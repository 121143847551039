import { Box, Skeleton, Typography } from "@mui/material";
import React from "react";
import SpeedIcon from "@mui/icons-material/Speed";
import {
  publicBlueMain,
  publicBorderRadius,
  publicRed,
} from "../../../Design/SourcesJS/publicTheme";
import { Motion } from "../../../Core/Motion/Motion";

export default function ArticleTextSkeleton({
  sxProps = {},
  sxButton = {},
  sxTitle = {}, // Mensi font u nekterych
  sxText = {},
  xsBlueText = {},
  icon = true,
  image = null,
  buttonText = false,
  children,
}) {
  const boxStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  };

  return (
    <Box
      sx={{
        ...boxStyle,
        pt: "20px",
        width: "100%",
        maxWidth: "340px",
        ...sxProps,
      }}
    >
      {image && (
        <Box sx={{ width: "100%", pb: "22px", height: "auto" }}>
          <Skeleton
            variant="rounded"
            sx={{
              width: "100%",
              minHeight: "200px",
              maxHeight: "230px",
              height: "100%",
              paddingTop: "60%",
              borderRadius: "20px",
            }}
          />
        </Box>
      )}
      <Box
        sx={{
          ...boxStyle,
          height: "20px",
          display: "flex",
          flexDirection: "row",
          alignItems: "bottom",
          alignContent: "bottom",
        }}
      >
        {icon && (
          <Skeleton
            variant="circular"
            width={20}
            height={20}
            sx={{ mr: "10px", minWidth: "20px", minHeight: "20px" }}
          />
        )}
        <Box
          sx={{
            height: "100%",
            width: "100%",
            position: "relative",
            display: "flex",
            alignItems: "flex-end",
            alignContent: "flex-end",
          }}
        >
          <Skeleton
            variant="text"
            height={24}
            sx={[
              {
                position: "absolute",
                minWidth: "90px",
                width: "50%",
                bottom: -2,
                fontSize: { xs: "15px", sm: "16px" },
                lineHeight: { xs: "16px", sm: "17px" },
              },
              { ...xsBlueText },
            ]}
          />
        </Box>
      </Box>
      <Skeleton
        variant="text"
        sx={[
          {
            mt: "18px",
            fontSize: { xs: "24px", sm: "26px" },
            lineHeight: { xs: "32px", sm: "34px" },
            ...sxTitle,
          },
          image && { fontSize: "26px", lineHeight: "32px" },
        ]}
      />
      <Skeleton
        variant="text"
        sx={[
          {
            mb: "18px",
            fontSize: { xs: "24px", sm: "26px" },
            lineHeight: { xs: "32px", sm: "34px" },
            ...sxTitle,
          },
          image && { fontSize: "26px", lineHeight: "32px" },
        ]}
      />
      <Skeleton variant="text" sx={[{ ...sxText }]} />
      <Skeleton variant="text" sx={[{ ...sxText }]} />
      <Skeleton variant="text" sx={[{ ...sxText }]} />

      {buttonText && (
        <Motion>
          <Skeleton
            variant="circular"
            sx={{
              mt: "18px",
              mb: "12px",
              height: "42px",
              width: "95px",
              borderRadius: "42px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          />
        </Motion>
      )}
      {children && children}
    </Box>
  );
}
