export const appGreyOutline = "#F9F9FA";
export const appGrey = "#444444";
export const appBlack = "#252525";
export const appWhite = "#F3F3F3";
export const appRed = "#F54F64";
export const appRedLight = "#FF5555";
export const appGreen = "#14A614";
export const appDarkGreen = "#026A02";
export const lightestBlue = "#358DE0"; //#2EAAFA

export const appBgColor = "#FBFBFB"; //#E8ECEF

export const errorRed = appRed;

export const placeholderGrey = "#8A8787";
export const navbarBlue = "#0265F4";
export const subTitleBlue = "#0256CF";
export const appDarkBlue = "#001949";
export const textGrey = "#73757A";
export const dividerGrey = "#D9D9D9";

export const blueGradient = `linear-gradient(90deg, ${navbarBlue} 0%, ${lightestBlue} 100%)`;

export const baseShadow3 = "1px 2px 5px 0px rgba(115,117,122,0.3)";
export const baseShadow36 = "1px 2px 6px 0px rgba(115,117,122,0.3)";

export const baseShadow2 = "1px 2px 5px 0px rgba(115,117,122,0.2)";
export const baseShadow5 = "1px 2px 5px 0px rgba(115,117,122,0.5)";
export const baseShadow8 = "1px 2px 5px 0px rgba(115,117,122,0.8)";

export const baseBorder = "1px solid #D9D9D9";
export const blueBorder = `2px solid ${navbarBlue}`;
export const errorBorder = `2px solid ${errorRed}`;

export const borderRadius = "10px";

export const zindex = {
  guideOverlay: 9998,
  menuOverlay: 9999,
  navbar: 10000,
  overlay: 10001,
  maxOverlay: 10020,
};

export const articleTitleFont = {
  fontSize: { xs: "21px", sm: "23px" },
  lineHeight: { xs: "29px", sm: "31px" },
  mb: "15px",
};

export const SCREEN_SIZE = 24;

export const FOOTER_HEIGHT = 180; // px

export const tableScrollbarBottom = {
  borderBottomLeftRadius: { xs: "6px", sm: "12px" },
  borderBottomRightRadius: { xs: "6px", sm: "12px" },
  "&::-webkit-scrollbar": {
    display: { xs: "block", sm: "none" },
    height: "6px",
  },
  "&::-webkit-scrollbar-track": {
    display: { xs: "block", sm: "none" },
    boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
  },
  "&::-webkit-scrollbar-thumb": {
    display: { xs: "block", sm: "none" },
    backgroundColor: "rgba(0,0,0,0.3)",
    outline: "none",
    borderRadius: "16px",
    borderTopLeftRadius: { xs: "0px", sm: "12px" },
    borderTopRightRadius: { xs: "0px", sm: "12px" },
  },
};

const coreThemeFile = () => {
  return {
    palette: {
      primary: {
        main: `${navbarBlue}`,
        light: `${lightestBlue}`,
        dark: `${navbarBlue}`,
        contrastText: "#fff",
      },
      secondary: {
        light: "#ff6333",
        main: "#ff3d00",
        dark: "#b22a00",
        contrastText: "#fff",
      },
      error: {
        light: appRed,
        main: appRed,
        dark: appRed,
      },
    },
    components: {
      MuiTypography: {
        defaultProps: {
          variantMapping: {
            logo: "p",
            navbar: "p",
            navbarSmall: "p",
            title: "h1",
            subtitle: "h2",
            tableText: "p",
            tableHeader: "h3",
            inputTitle: "p",
            buttonText: "p",
            settingsButtonText: "p",
            groupButtonText: "p",
            floatingButton: "p",
            error: "p",
            /* SETTINGS TEXTX */
            settHeader: "h2",
            settHeaderSmall: "h3",
            settText: "p",
            /* SMALL NAVIGATION */
            navHeader: "h2",
            navText: "p",
            /* FOOTER */
            footerText: "p",
          },
        },
        styleOverrides: {
          root: ({ ownerState }) => ({
            textTransform: "none",

            ...(ownerState.variant === "logo" && {
              fontSize: "17px",
              fontFamily: "Rawline",
              fontWeight: 900,
              textTransform: "uppercase",
              lineHeight: "22px",
              color: appWhite,
            }),
            ...(ownerState.variant === "navbarSmall" && {
              fontSize: "13px",
              fontFamily: "Rawline",
              fontWeight: 900,
              textTransform: "uppercase",
              lineHeight: "18px",
            }),
            ...(ownerState.variant === "navbar" && {
              fontSize: "13px",
              fontWeight: 700,
              textTransform: "capitalize",
              color: appWhite,
            }),
            ...(ownerState.variant === "title" && {
              fontSize: "32px",
              fontWeight: 700,
              fontFamily: "Gordita",
              lineHeight: "38px",
              color: navbarBlue,
            }),
            ...(ownerState.variant === "subtitle" && {
              fontSize: "14px",
              fontWeight: 700,
              fontFamily: "Gordita",
              lineHeight: "14px",
              color: appDarkBlue,
            }),
            ...(ownerState.variant === "tableText" && {
              fontSize: "14px",
              lineHeight: "15px",
              color: placeholderGrey,
              textAlign: "left",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: 2,
              lineClamp: 2,
              WebkitBoxOrient: "vertical",
            }),
            ...(ownerState.variant === "tableHeader" && {
              fontSize: "14px",
              lineHeight: "15px",
              color: appDarkBlue,
              textAlign: "left",
            }),
            ...(ownerState.variant === "inputTitle" && {
              fontSize: "13px",
              fontWeight: 700,
              fontFamily: "Gordita",
              lineHeight: "13px",
              color: appDarkBlue,
            }),
            ...(ownerState.variant === "buttonText" && {
              fontSize: "17px",
              lineHeight: "17px",
              fontWeight: "bold",
              color: "white",
              width: "100%",
              alignSelf: "center",
              textTransform: "none",
            }),
            ...(ownerState.variant === "settingsButtonText" && {
              fontSize: "15px",
              lineHeight: "15px",
              fontWeight: "bold",
              width: "100%",
              alignSelf: "center",
              textTransform: "none",
            }),
            ...(ownerState.variant === "groupButtonText" && {
              fontSize: "15px",
              lineHeight: "15px",
              fontWeight: "bold",
              width: "100%",
              alignSelf: "center",
              textTransform: "none",
            }),
            ...(ownerState.variant === "floatingButton" && {
              fontSize: "17px",
              lineHeight: "17px",
              fontWeight: "bold",
              alignSelf: "center",
              textTransform: "none",
              color: "white",
            }),
            ...(ownerState.variant === "h1" && {
              fontSize: "22px",
            }),
            ...(ownerState.variant === "body1" && {
              fontSize: "14px",
            }),
            /* SETTINGS PAGE */
            ...(ownerState.variant === "settHeaderSmall" && {
              fontFamily: "Gordita",
              fontWeight: "bold",
              color: navbarBlue,
              fontSize: "10px",
              lineHeight: "10px",
              width: "100%",
              textTransform: "none",
            }),
            ...(ownerState.variant === "settHeader" && {
              fontFamily: "Gordita",
              fontWeight: "bold",
              color: appDarkBlue,
              fontSize: "16px",
              lineHeight: "16px",
              width: "100%",
              textTransform: "none",
            }),
            ...(ownerState.variant === "settText" && {
              color: appDarkBlue,
              fontSize: "11px",
              lineHeight: "14px",
              width: "100%",
              textTransform: "none",
            }),
            ...(ownerState.variant === "navHeader" && {
              color: appDarkBlue,
              fontFamily: "Gordita",
              fontWeight: "bold",
              fontSize: "14px",
              lineHeight: "14px",
            }),
            ...(ownerState.variant === "navText" && {
              color: textGrey,
              fontSize: "12px",
            }),
            ...(ownerState.variant === "footerText" && {
              color: "white",
              fontFamily: "Gordita",
              fontWeight: "bold",
              fontSize: "14px",
              lineHeight: "14px",
            }),
            ...(ownerState.variant === "error" && {
              color: errorRed,
              fontWeight: "bold",
              fontSize: "14px",
              lineHeight: "14px",
            }),
          }),
        },
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
          //disableFocusRipple: true,
        },
      },
      MuiButton: {
        styleOverrides: {
          root: ({ ownerState }) => ({}),
        },
      },
      MuiGrid: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            padding: 0,
          }),
        },
      },
    },
    typography: {
      useNextVariants: true,
    },
  };
};
export default coreThemeFile;
