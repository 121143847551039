import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import {
  appDarkBlue,
  navbarBlue,
  textGrey,
} from "../../Design/SourcesJS/themes";
import { Box } from "@mui/material";
import { Motion } from "../Motion/Motion";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import BaseButton from "../Buttons/BaseButton";

export default function InfoDialog({
  text,
  open = false,
  handleClose,
  chooseFunction = null,
  dialog = undefined,
  sxContent = {},
  handleOutsideClose = null,
}) {
  if (dialog) {
    text = dialog?.text || text;
    open = Boolean(dialog) || open;
    chooseFunction = dialog?.chooseFunction || chooseFunction;
    handleOutsideClose = dialog?.handleOutsideClose || handleOutsideClose;
  }

  return (
    <Dialog
      open={open}
      onClose={handleOutsideClose ? handleOutsideClose : handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        zIndex: 20000,
        ".MuiPaper-root": {
          borderRadius: "24px",
          minWidth: "280px",
          width: "410px",
          maxWidth: "430px",
          minHeight: "160px",
          boxShadow: "none",
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
        },
      }}
    >
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          alignContent: "center",
          justifyContent: "center",
          ...sxContent,
        }}
      >
        <Motion>
          <Box
            sx={{
              cursor: "pointer",
              position: "absolute",
              right: 10,
              top: 10,
              height: "20px",
              width: "20px",
            }}
            onClick={() => {
              if (handleClose) handleClose();
            }}
          >
            <CloseRoundedIcon
              sx={{ fill: textGrey, height: "20px", width: "20px" }}
            />
          </Box>
        </Motion>

        <DialogContentText
          component={"span"}
          sx={{
            fontSize: "18px",
            color: appDarkBlue,
            fontWeight: "bold",
            fontFamily: "Roboto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            alignContent: "center",
            textAlign: "center",
          }}
          id="alert-dialog-description"
        >
          {text}
        </DialogContentText>
        {chooseFunction && (
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <BaseButton
              text={"Potvrdit"}
              onClick={(e) => {
                if (chooseFunction) {
                  chooseFunction();
                }
                handleClose();
              }}
              sxProps={{
                mx: "20px",
                textAlign: "center",
                mb: "0px",
                mt: "26px",
                maxWidth: "200px",
                bgcolor: navbarBlue,
              }}
            />
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
}
