import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import {
  adminRoutes,
  legalRoutes,
  publicRoutes,
} from "./Resources/Routes/routes";
import LoadingBackdrop from "./Resources/Core/Layouts/LoadingBackdrop";
import { HelmetProvider } from "react-helmet-async";
//import "./Resources/Design/Fonts/Fonts.css";

if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

const isAdminSubdomain = () => {
  return Boolean(window.location.host.split(".")[0] == "admin");
};

const getRoutes = (admin = false) => {
  let routes = [...legalRoutes];
  if (admin) {
    routes.push(...adminRoutes);
  } else {
    routes.push(...publicRoutes);
  }

  return [
    ...routes.map((route) => ({
      path: route.path,
      element: route.element,
      errorElement: <></>,
    })),
  ];
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <App isAdmin={isAdminSubdomain()} />,
    children: getRoutes(isAdminSubdomain()),
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <Suspense fallback={<LoadingBackdrop open={true} />}>
      <HelmetProvider>
        <RouterProvider router={router} />
      </HelmetProvider>
    </Suspense>
  </>
);

reportWebVitals();
