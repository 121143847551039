import { Box, TextField, Typography } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React from "react";
import "dayjs/locale/cs";

import {
  appDarkBlue,
  appRed,
  baseShadow3,
  navbarBlue,
} from "../../Design/SourcesJS/themes";
import { centerGridContent } from "./TextInput";

export function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

export function dateToVisual(date) {
  try {
    return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
  } catch (err) {
    return date;
  }
}

export function timestampToDate(timestamp) {
  try {
    if (isValidDate(timestamp)) {
      return timestamp;
    }

    let date = new Date(timestamp.toDate());

    if (!isValidDate(date)) {
      throw new Error("Invalid Date");
    }
    return date;
  } catch (err) {
    if (timestamp?.seconds) {
      let date = new Date(timestamp.seconds * 1000);
      return date;
    } else {
      return null;
    }
  }
}

export default function DateInput(props) {
  const { name, value, text, sxProps, handleChange, error, sxBox } = props;

  const otherProps = { ...props };
  delete otherProps.value;
  delete otherProps.text;
  delete otherProps.sxProps;
  delete otherProps.error;
  delete otherProps.sxBox;
  delete otherProps.name;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"cs"}>
      <Box
        {...centerGridContent}
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          ...sxBox,
        }}
      >
        <Box sx={{ width: "100%", textAlign: "left" }}>
          <Typography variant="inputTitle" sx={{}}>
            {text}
          </Typography>
        </Box>
        <DesktopDatePicker
          acceptRegex={/\D/gi}
          disableMaskedInput
          inputFormat="D.M.YYYY"
          value={value}
          onChange={(date) => {
            //console.log(date);
            handleChange(name, date);
          }}
          renderInput={(params) => (
            <TextField
              sx={{
                mt: "0px",
                border: "none",

                ".MuiFormControl-root": {
                  height: "100%",
                },
                ".MuiInputBase-root": {
                  //boxShadow: baseShadow3,
                  borderRadius: "10px",
                  bgcolor: "white",
                  height: "55px",
                  maxHeight: "55px",
                },
                "& .MuiOutlinedInput-root": {
                  height: "55px",
                  "& fieldset": {
                    border: error ? `2px solid ${appRed}` : "none",
                    boxShadow: baseShadow3,
                  },
                  "&:hover fieldset": {
                    border: error
                      ? `2px solid ${appRed}`
                      : `2px solid ${navbarBlue}`,
                    boxShadow: "1px 2px 5px 0px rgba(115,117,122,0.3)",
                  },
                  "&.Mui-focused fieldset": {
                    border: error
                      ? `2px solid ${appRed}`
                      : `2px solid ${navbarBlue}`,
                    boxShadow: "1px 2px 5px 0px rgba(115,117,122,0.4)",
                  },
                },
                "&.MuiOutlinedInput-input": {
                  backgroundColor: "white",
                  background: "none",
                },
                ".MuiInputBase-input::placeholder": {
                  color: "black",
                },
                width: "100%",
                ...sxProps,
              }}
              {...params}
              onChange={(e) => {
                //console.log(e);
                e.preventDefault();
                e.stopPropagation();
              }}
              value={dateToVisual(new Date())}
            />
          )}
          sx={{ mt: "10px", ...sxProps }}
        />
      </Box>
    </LocalizationProvider>
  );
}
