import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import React from "react";

import InfoIcon from "../Base/InfoIcon";

import {
  baseShadow2,
  baseShadow3,
  errorRed,
  navbarBlue,
} from "../../Design/SourcesJS/themes";

export const centerGridContent = {
  align: "center",
  justify: "center",
  alignItems: "center",
};

export default function TextInput(props) {
  const {
    text,
    placeholder,
    sxText,
    sxBox,
    error,
    name = "InputBase",
    type = "text",
    fullSize = false,
    endAdornment = false,
    startAdornment = false,
    required = false,
    multiline = false,
    tooltip,
    sxTooltip = {},
    disabled,
    innerRef,
    disableTitle = false,
  } = props;

  const otherProps = { ...props };
  delete otherProps.text;
  delete otherProps.placeholder;
  delete otherProps.sxText;
  delete otherProps.sxBox;
  delete otherProps.name;
  delete otherProps.fullSize;
  delete otherProps.endAdornment;
  delete otherProps.startAdornment;
  delete otherProps.tooltip;
  delete otherProps.innerRef;
  delete otherProps.sxTooltip;
  delete otherProps.disableTitle;

  return (
    <Box
      ref={innerRef || null}
      {...centerGridContent}
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        ...sxBox,
      }}
    >
      <Box
        sx={{
          width: "100%",
          textAlign: "left",
          display: "flex",
          flexDirection: "row",
        }}
      >
        {!disableTitle && (
          <Typography variant="inputTitle" sx={{}}>
            {required ? `${text}*` : text}&nbsp;
          </Typography>
        )}
        {tooltip && (
          <Box
            sx={{
              position: "relative",
              width: "13px",
              height: "13px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <InfoIcon
              sxButton={{
                position: "absolute",
                left: 2,
                top: 0,
                bottom: 0,
                width: "18px",
                height: "18px",
                ...sxTooltip,
              }}
              sxIcon={{ width: "14px", height: "14px" }}
              text={tooltip}
            />
          </Box>
        )}
      </Box>
      <TextField
        name={name}
        type={type}
        id={name}
        InputProps={{
          endAdornment: endAdornment ? (
            <InputAdornment position="end">{endAdornment}</InputAdornment>
          ) : (
            <></>
          ),
          startAdornment: startAdornment ? (
            <InputAdornment position="start">{startAdornment}</InputAdornment>
          ) : (
            <></>
          ),
        }}
        sx={{
          mt: fullSize ? "0px" : "10px",
          border: "none",
          ".MuiFormControl-root": {
            height: "100%",
          },
          ".MuiInputBase-root": {
            //boxShadow: baseShadow3,
            borderRadius: "10px",
            bgcolor: "white",
            height: multiline ? "auto" : "50px",
            maxHeight: multiline ? "auto" : "50px",
            //border: baseBorder,
          },
          "& .MuiOutlinedInput-root": {
            height: multiline ? "auto" : "50px",
            "& fieldset": {
              border: error ? `2px solid ${errorRed}` : "none", //`1px solid rgba(115,117,122,0.3)`
              boxShadow: baseShadow3,
            },
            "&:hover fieldset": {
              border: error
                ? `2px solid ${errorRed}`
                : disabled
                ? "none"
                : `2px solid ${navbarBlue}`,
              boxShadow: "1px 2px 5px 0px rgba(115,117,122,0.3)",
            },
            "&.Mui-focused fieldset": {
              border: error
                ? `2px solid ${errorRed}`
                : disabled
                ? "none"
                : `2px solid ${navbarBlue}`,
              boxShadow: "1px 2px 5px 0px rgba(115,117,122,0.4)",
            },
          },
          "&.MuiOutlinedInput-input": {
            backgroundColor: "white",
            background: "none",
          },
          ".MuiInputBase-input::placeholder": {
            color: "black",
          },
          width: "100%",
          ...sxText,
        }}
        placeholder={placeholder}
        variant="outlined"
        {...otherProps}
      />
    </Box>
  );
}
