import {
  Box,
  Pagination,
  PaginationItem,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { publicTheme } from "../../../../../App";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import {
  NOT_SELECTABLE,
  publicBlueDark,
} from "../../../../Design/SourcesJS/publicTheme";
import { ARTICLES_PER_PAGE } from "../BlogHomePage-5";

export default function BlogPagination({
  articlesCount,
  page = 1,
  pageSize = ARTICLES_PER_PAGE,
  onPreviousClick = () => {},
  onNextClick = () => {},
}) {
  const pc = useMediaQuery(publicTheme.breakpoints.up("md"));

  return (
    <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
      <Box
        sx={{
          mt: "30px",
          display: "flex",
          flexDirection: "row",
          width: "100%",
          maxWidth: "890px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            alignContent: "center",
            px: "11px",
            cursor: page == 1 ? "default" : "pointer",
            mr: "auto",
          }}
          onClick={() => {
            if (page == 1) return;
            if (onPreviousClick) {
              onPreviousClick();
            }
          }}
        >
          <ArrowBackRoundedIcon
            sx={{
              fontSize: "15px",
              mr: "5px",
              color: page == 1 ? "grey" : publicBlueDark,
            }}
          />
          <Typography
            sx={{
              fontSize: "12px",
              fontFamily: "Roboto",
              fontWeight: "500",
              color: page == 1 ? "grey" : publicBlueDark,
              ...NOT_SELECTABLE,
            }}
          >
            Předchozí strana
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            alignContent: "center",
            px: "11px",
            cursor:
              page == Math.ceil(articlesCount / pageSize)
                ? "default"
                : "pointer",
          }}
          onClick={() => {
            if (page == Math.ceil(articlesCount / pageSize)) return;
            if (onNextClick) {
              onNextClick();
            }
          }}
        >
          <Typography
            sx={{
              fontSize: "12px",
              fontFamily: "Roboto",
              fontWeight: "500",
              color:
                page == Math.ceil(articlesCount / pageSize)
                  ? "grey"
                  : publicBlueDark,
              ml: "auto",
              ...NOT_SELECTABLE,
            }}
          >
            Další strana
          </Typography>
          <ArrowForwardRoundedIcon
            sx={{
              fontSize: "15px",
              ml: "5px",
              color:
                page == Math.ceil(articlesCount / pageSize)
                  ? "grey"
                  : publicBlueDark,
            }}
          />
        </Box>
        {/*pc ? (
          <Pagination
            count={Math.ceil(articlesCount / pageSize)}
            page={page}
            onChange={() => {}}
            color="primary"
            sx={{
              "& .MuiPaginationItem-previousNext": {
                mx: "40px !important",
              },
            }}
            renderItem={(item) => (
              <PaginationItem
                slots={{
                  previous: (props) => (
                    <Box
                      {...props}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        alignContent: "center",
                        px: "11px",
                      }}
                    >
                      <ArrowBackRoundedIcon
                        sx={{ fontSize: "15px", mr: "5px" }}
                      />
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontFamily: "Roboto",
                          fontWeight: "500",
                        }}
                      >
                        Předchozí strana
                      </Typography>
                    </Box>
                  ),
                  next: (props) => (
                    <Box
                      {...props}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        alignContent: "center",
                        px: "11px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontFamily: "Roboto",
                          fontWeight: "500",
                        }}
                      >
                        Další strana
                      </Typography>
                      <ArrowForwardRoundedIcon
                        sx={{ fontSize: "15px", ml: "5px" }}
                      />
                    </Box>
                  ),
                }}
                {...item}
              />
            )}
          />
        ) : (
          <Pagination
            count={Math.ceil(articlesCount / pageSize)}
            page={page}
            onChange={() => {}}
            color="primary"
          />
        )*/}
      </Box>
    </div>
  );
}
