import React, { Component, lazy } from "react";
import PublicNavbar from "../../Components/Navbars/PublicNavbar";
import BlogNavbar from "./Components/BlogNavbar";

import {
  Box,
  Container,
  Grid,
  Pagination,
  PaginationItem,
  Typography,
} from "@mui/material";
import { NAVBAR_HEIGHT, NAVBAR_SPACE } from "../../Classes/Constants";
import MainPageArticles from "../../Components/Layouts/ArticleContainers/MainPageArticles";
import { appBgColor } from "../../../Design/SourcesJS/themes";
import BlogSideNavigation from "./Components/BlogSideNavigation";
import {
  getArticleDoc,
  getArticleFull,
  getBlogArticles,
  getBlogSections,
  getSideArticles,
} from "./Firebase/BlogControls";
import ArticleText from "../../Components/Texts/ArticleTextHeader/ArticleText";
import ArticleTest from "../../../Design/Public/Pages/ArticleTest.png";
import { withRouter } from "../../../Routes/withRouter";
import ArticleTextSkeleton from "../../Components/Texts/ArticleTextSkeleton";
import BlogSideNavigationSkeleton from "./Components/BlogSideNavigationSkeleton";
import ClanekView from "./ClanekView";
import ArticleHeaderBlog from "../../Components/Texts/ArticleTextHeader/ArticleHeaderBlog";
import ArticleHeaderBlogSkeleton from "../../Components/Texts/ArticleHeaderBlogSkeleton";
import { withResolution } from "./Components/withResolution";
import BlogTopNavigation from "./Components/BlogTopNavigation";
import WestRoundedIcon from "@mui/icons-material/WestRounded";
import {
  publicBlueDark,
  publicBlueMain,
  publicRed,
} from "../../../Design/SourcesJS/publicTheme";
import BlogPagination from "./Components/BlogPagination";
import LoadingBackdrop from "../../../Core/Layouts/LoadingBackdrop";
import { withContext } from "../../Classes/withContext";

import InfoDialog from "../../../Core/Base/InfoDialog";
import HelmetBlog from "./HelmetBlog";

const ContactForm = lazy(() => import("../../Components/Layouts/ContactForm"));

export const ARTICLE_TEXT_CLAMP = {
  display: "-webkit-box",
  WebkitLineClamp: 3,
  lineClamp: 3,
  overflow: "hidden",
  WebkitBoxOrient: "vertical",
  MozOrient: "vertical",
};
export const ARTICLE_TITLE_CLAMP = {
  display: "-webkit-box",
  WebkitLineClamp: 2,
  lineClamp: 2,
  overflow: "hidden",
  WebkitBoxOrient: "vertical",
  MozOrient: "vertical",
};

export const ARTICLES_PER_PAGE = 7;
const LEFT_SPACE = "200px";
export const CONTAIN_WIDTH = "380px";

function orderSectionDataToArray(sectionsList, podpora = false) {
  let allSection = "vsechny_clanky";
  if (podpora) {
    allSection = "nastaveni_profaktury";
  }

  let sec = Object.keys(sectionsList);
  if (sec) {
    var index = sec.indexOf(allSection);
    if (index !== -1) {
      sec.splice(index, 1);
      sec.unshift(allSection);
    }
  }
  let finalArr = [];
  for (let i = 0; i < sec.length; i++) {
    finalArr.push({
      id: sectionsList[sec[i]],
      name: sectionsList[sec[i]].name,
      count: sectionsList[sec[i]].count,
    });
  }
}

class BlogHomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sectionsList: null,
      loading: true,
      loadingBackdrop: false,
      currentSectionData: null, // {id, name, count}
      currentArticle: null,
      currentPage: 1,
      podpora: this.props.location?.pathname === "/podpora" || false,
      sideArticles: null,
    };
    this.onSectionClick = this.onSectionClick.bind(this);
    this.openArticle = this.openArticle.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  handleFormSubmit(data, resetForm) {
    this.setState({ loadingBackdrop: true }, async () => {
      const { sendSupportForm } = await import("../../../Firebase/init");

      sendSupportForm({ ...data, typ: "supp" })
        .then((result) => {
          this.setState({
            loadingBackdrop: false,
            dialog: { text: "Zpráva úspěšně odeslána" },
          });
          if (resetForm) {
            resetForm();
          }
        })
        .catch((err) => {
          this.setState({
            loadingBackdrop: false,
            dialog: {
              text: "Omlouváme se, zprávu se nepodařilo odeslat. Kontaktovat nás můžete kdykoliv na email podpora@profaktura.cz",
            },
          });
        });
    });
  }

  getBlogSectionsAsync = async () => {
    return new Promise(async (resolve, reject) => {
      getBlogSections(this.state.podpora)
        .then((sectionsList) => {
          console.log("slist", sectionsList);
          orderSectionDataToArray(sectionsList, this.state.podpora);
          let currentSectionData = this.getCurrentSectionData(sectionsList);

          this.setState(
            {
              sectionsList,
              currentArticle: this.props.searchParams.get("clanek") || null,
              currentSectionData,
            },
            () => {
              resolve(this.state.sectionsList);
            }
          );
        })
        .catch((err) => {
          this.setState(
            {
              dialog: {
                text: "Omlouváme se, stránka je momentálně nedostupná.",
              },
            },
            () => {
              reject(err);
            }
          );
        });
    });
  };

  getCurrentSectionData(
    sectionsList = this.state.sectionsList,
    sekce = this.getDefaultSection()
  ) {
    if (sekce && sectionsList && Object.keys(sectionsList).includes(sekce)) {
      return { id: sekce, ...sectionsList[sekce] };
    } else if (!sekce) {
      return {
        id: null,
        name: null,
        count: this.countAllSections(sectionsList),
      };
    } else {
      return {};
    }
  }

  countAllSections(sectionsList) {
    let total = 0;
    Object.keys(sectionsList).map((key) => {
      total += sectionsList[key].count;
    });
    return total;
  }

  getDefaultSection() {
    return (
      this.props.searchParams.get("sekce") ||
      (this.state.podpora ? "nastaveni_profaktury" : null)
    );
  }

  setStateAsync = async (key, data) => {
    return new Promise(async (resolve, reject) => {
      this.setState({ [key]: data }, () => {
        return resolve();
      });
    });
  };

  componentDidMount = async () => {
    //if (!this.props.data) return;
    console.log("MOUNT", this.state.podpora);
    // CHECKING IF SECTIONS LOADED (Kategorie)
    let sections = this.state.sectionsList;
    if (!sections) {
      sections = await this.getBlogSectionsAsync();
    }
    console.log(sections);
    // CHECKING URL QUERY PARAMS
    let clanek = this.props.searchParams.get("clanek") || null;
    let sekce = this.getDefaultSection();
    if (sekce) {
      sekce = Object.keys(sections).includes(sekce) ? sekce : null;
    }
    console.log(Boolean(clanek));
    if (clanek) {
      getArticleFull(clanek, this.state.podpora)
        .then((result) => {
          this.setState({
            currentArticle: result,
            currentSectionData: this.getCurrentSectionData(
              sections,
              result.section
            ),
          });
          return getSideArticles(
            this.state.podpora,
            result.section,
            result.uniqueId
          );
        })
        .then((articles) => {
          console.log("SideArticles ", articles);
          this.setState({ loading: false, sideArticles: articles });
        })
        .catch((err) => {
          console.log("Err loading article full ", err);
          this.onSectionClick(null, true);
        });
    } else {
      this.loadSectionArticles(sekce);
    }
  };

  loadSectionArticles(sekce) {
    getBlogArticles(sekce, null, 7, this.state.podpora)
      .then((result) => {
        this.setState(
          {
            [sekce || "home"]: {
              list: result.articles,
              lastVisible: result.lastRef,
            },
            currentPage: 1,
            loading: false,
          },
          () => {
            window.scrollTo(0, 0);
          }
        );
      })
      .catch((err) => {
        console.log("Errgetblog ", err);
        this.onSectionClick(null, true);
      });
  }

  loadNextMissingArticles() {
    this.setState({ loading: true }, () => {
      let sekceId = this.state.currentSectionData?.id || null;
      getBlogArticles(
        sekceId,
        this.state[sekceId || "home"].lastVisible,
        7,
        this.state.podpora
      )
        .then((result) => {
          this.setState(
            {
              [sekceId || "home"]: {
                list: [
                  ...this.state[sekceId || "home"].list,
                  ...result.articles,
                ],
                lastVisible: result.lastRef,
              },
              loading: false,
              currentPage: this.state.currentPage + 1,
            },
            () => {
              window.scrollTo(0, 0);
            }
          );
        })
        .catch((err) => {
          console.log("ErrGetBlog ", err);
          this.onSectionClick(null, true);
        });
    });
  }

  doArticlesExist(
    nextPageCheck = false,
    sekcePrefId = null,
    specificPage = false,
    forceHome = false
  ) {
    let page = specificPage || this.state.currentPage;
    if (nextPageCheck) {
      page += 1;
    }
    let sekceId = sekcePrefId || this.state.currentSectionData?.id || "home";
    if (forceHome) {
      sekceId = "home";
    }

    if (sekceId && this.state[sekceId] && this.state[sekceId].list) {
      let total = this.state[sekceId]?.list?.length;
      let atLeastNeeded = page * ARTICLES_PER_PAGE - ARTICLES_PER_PAGE + 1;

      if (total && total >= atLeastNeeded) {
        return true;
      }
    }
    return false;
  }

  getPaginatedArticles() {
    let sekceId = this.state.currentSectionData?.id || "home";

    if (!this.state[sekceId] || !this.state[sekceId].list) return [];

    let array = [...this.state[sekceId].list];
    let page_number = this.state.currentPage;
    let page_size = ARTICLES_PER_PAGE;
    return array.slice((page_number - 1) * page_size, page_number * page_size);
  }

  onNextPageClick = () => {
    if (this.doArticlesExist(true)) {
      this.setState({ currentPage: this.state.currentPage + 1 }, () => {
        window.scrollTo(0, 0);
      });
    } else {
      this.loadNextMissingArticles();
    }
    window.scrollTo({ top: 0, left: 0 });
  };

  onPreviousPageClick = () => {
    this.setState({ currentPage: this.state.currentPage - 1 });
    window.scrollTo({ top: 0, left: 0 });
  };

  onSectionClick(sectionName) {
    this.navigateTo(sectionName);
  }

  sortThreeNewestFromArray(arr, idToExclude) {
    let newArr = [...arr];
    const uniqueIds = {};
    // Filter out duplicates based on the 'id' key
    const filteredData = newArr.filter((obj) => {
      if (obj.id === idToExclude) {
        return false;
      }
      if (!uniqueIds[obj.id]) {
        uniqueIds[obj.id] = true;
        return true;
      }
      return false;
    });

    if (filteredData.length < 3) return [];

    filteredData.sort((a, b) => new Date(b.date) - new Date(a.date));

    // Get the three objects with the newest dates
    const newestObjects = filteredData.slice(0, 3);

    return newestObjects;
  }

  getThreeNewestArticles(currentArticle) {
    if (!this.state.sectionsList) return [];
    let currentSectionData = this.state.currentSectionData;
    console.log(this.state);
    if (
      currentSectionData?.id &&
      this.state[currentSectionData?.id] &&
      this.state[currentSectionData?.id].length > 2
    ) {
      return [
        ...this.sortThreeNewestFromArray(
          this.state[currentSectionData?.id],
          currentArticle?.id
        ),
      ];
    }

    let secArr = [
      "home",
      Object.keys(this.state.sectionsList).map((key) => key),
    ];
    let artArr = [];
    for (let i = 0; i < secArr.length; i++) {
      if (this.state[secArr[i]] && this.state[secArr[i]].list) {
        artArr.push(...this.state[secArr[i]].list);
      }
    }
    // Track unique IDs
    const uniqueIds = {};

    // Filter out duplicates based on the 'id' key
    const filteredData = artArr.filter((obj) => {
      if (!uniqueIds[obj.id]) {
        uniqueIds[obj.id] = true;
        return true;
      }
      return false;
    });

    if (filteredData.length < 3) return [];

    filteredData.sort((a, b) => new Date(b.date) - new Date(a.date));

    // Get the three objects with the newest dates
    const newestObjects = filteredData.slice(0, 3);

    return newestObjects;
  }

  openArticle(section, articleId) {
    console.log(section, articleId);
    this.navigateTo(section, articleId);
  }

  getSectionsListSideNav() {
    const sectionsList = this.state.sectionsList;

    let sortedSectionsList = Object.keys(sectionsList)
      .slice()
      .sort((a, b) => a.localeCompare(b));

    if (this.state.podpora) {
      const origSect = [...sortedSectionsList];
      sortedSectionsList = [
        "nastaveni_profaktury",
        ...origSect.filter((key) => key !== "nastaveni_profaktury"),
      ];
    }
    return sortedSectionsList;
  }

  navigateTo(section, article) {
    this.props.navigate(
      (this.state.podpora ? "/podpora" : `/blog`) +
        (section ? `?sekce=${section}` : ``) +
        (article ? `&clanek=${article}` : ``)
    );
  }

  render() {
    return (
      <div
        style={{
          overflowX: "hidden",
          overflowY: "hidden",
          maxWidth: "100%",
          position: "relative",
          minWidth: "305px",
        }}
      >
        <HelmetBlog
          podpora={this.state.podpora}
          article={this.state.currentArticle}
        />
        <InfoDialog
          text={this.state.dialog?.text}
          open={Boolean(this.state.dialog)}
          handleClose={() => {
            this.setState({ dialog: null });
          }}
        />
        <PublicNavbar whiteVersion tryButtonColor={publicRed} />
        <BlogNavbar
          currentSection={this.state.currentSectionData?.id}
          sectionsList={this.state.sectionsList}
          currentArticle={this.state.currentArticle}
          blogChangeUrl={this.onSectionClick}
          podpora={this.state.podpora}
        />

        <LoadingBackdrop open={Boolean(this.state.loadingBackdrop)} />

        {this.state.currentArticle && !this.state.loading ? (
          <ClanekView
            article={this.state.currentArticle}
            threeArticles={this.state.sideArticles}
            podpora={this.state.podpora}
            openArticle={this.openArticle}
          />
        ) : (
          <Container
            maxWidth="lg"
            sx={{
              mt: "20px",
              px: "0px !important",
              position: "relative",
            }}
          >
            <Box
              sx={{
                pt: "20px",
                display: "flex",
                flexDirection: "row",
                minHeight: "100px",
                width: "100%",
                position: "relative",
              }}
            >
              {!this.props.mobile && (
                <Box
                  sx={{
                    ml: NAVBAR_SPACE,
                    position: "absolute",
                    display: "flex",
                    alignItems: "center",
                    alignContent: "center",
                  }}
                  onClick={() => {
                    if (!this.state.currentSectionData?.id) {
                      this.props.navigate("/");
                    } else {
                      this.onSectionClick(null, true);
                    }
                  }}
                >
                  <WestRoundedIcon
                    sx={{
                      color: publicBlueMain,
                      height: "30px",
                      width: "30px",
                      cursor: "pointer",
                    }}
                  />
                </Box>
              )}
              <Box
                sx={{
                  width: "100%",
                  ml: this.props.pc ? LEFT_SPACE : NAVBAR_SPACE,
                  mr: NAVBAR_SPACE,
                }}
              >
                <Grid container item spacing={5} columns={24} sx={{}}>
                  <Grid
                    item
                    xs={24}
                    sm={24}
                    md={12}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Box
                      sx={{
                        width: !this.props.pc ? "100%" : CONTAIN_WIDTH,
                        display: "flex",
                        justifyContent: {
                          xs: "flex-start",
                          sm: "flex-end",
                          md: "flex-start",
                        },
                      }}
                    >
                      {!this.state.loading && (
                        <Typography
                          variant="title"
                          sx={{
                            color: publicBlueDark,
                            //ml: "0px",
                            whiteSpace: { xs: "normal", sm: "nowrap" },
                            textAlign: "left",
                            fontSize: { xs: "28px", sm: "32px" },
                          }}
                        >
                          {this.state.sectionsList &&
                          this.state.currentSectionData &&
                          this.state.currentSectionData?.id
                            ? this.state.sectionsList[
                                this.state.currentSectionData?.id
                              ].name
                            : this.state.podpora
                            ? "Podpora"
                            : "Blog"}
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                  {this.props.pc && (
                    <Grid
                      item
                      xs={24}
                      sm={12}
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    ></Grid>
                  )}
                </Grid>
              </Box>
            </Box>
            {this.state.sectionsList ? (
              <>
                {this.props.pc ? (
                  <BlogSideNavigation
                    podpora={this.state.podpora}
                    sectionsList={this.state.sectionsList}
                    sortedSectionsList={this.getSectionsListSideNav()}
                    currentSection={this.state.currentSectionData.id}
                    onSectionClick={this.onSectionClick}
                    articlesCount={this.getPaginatedArticles().length}
                  />
                ) : (
                  <BlogTopNavigation
                    sectionsList={this.state.sectionsList}
                    currentSection={this.state.currentSectionData.id}
                    onSectionClick={this.onSectionClick}
                  />
                )}
              </>
            ) : (
              <> {this.props.pc && <BlogSideNavigationSkeleton />}</>
            )}

            <Box
              sx={{
                height: "auto",
                ml: this.props.pc ? LEFT_SPACE : NAVBAR_SPACE,
                //bgcolor: "grey",
                mr: NAVBAR_SPACE,
              }}
            >
              <Grid container item spacing={5} columns={24} sx={{}}>
                {this.doArticlesExist() ? (
                  <>
                    {this.getPaginatedArticles().map((article, index) => (
                      <React.Fragment key={`ArtTest` + index}>
                        {index === 0 ? (
                          <ArticleHeaderBlog
                            key={
                              `${
                                this.state.podpora ? "Podpora" : "Blog"
                              }PageArticleI` + index
                            }
                            image={article.imageUrl}
                            blueText={article.smallTitle}
                            title={article.title}
                            text={article.text}
                            sxTitle={{ maxWidth: "100%" }}
                            sxText={{ ...ARTICLE_TEXT_CLAMP }}
                            sxProps={{
                              maxWidth: !this.props.pc ? "100%" : CONTAIN_WIDTH,
                            }}
                            buttonText={"Přečíst"}
                            sxButton={{ bgcolor: publicBlueMain }}
                            onButtonClick={() => {
                              this.openArticle(article.section, article.id);
                            }}
                            minutes={
                              this.state.podpora ? null : article.sideArticles
                            }
                          />
                        ) : (
                          <Grid
                            item
                            xs={24}
                            sm={12}
                            sx={{ display: "flex", justifyContent: "flex-end" }}
                            key={
                              `${
                                this.state.podpora ? "Podpora" : "Blog"
                              }PageArticleI` + index
                            }
                          >
                            <ArticleText
                              image={article.imageUrl}
                              blueText={article.smallTitle}
                              title={article.title}
                              text={article.text}
                              buttonText={"Přečíst"}
                              sxTitle={{
                                fontSize: { xs: "22px", sm: "24px" },
                                lineHeight: { xs: "30px", sm: "32px" },
                                maxWidth: "100%",
                              }}
                              sxText={{ ...ARTICLE_TEXT_CLAMP }}
                              sxProps={{
                                maxWidth: !this.props.pc
                                  ? "100%"
                                  : CONTAIN_WIDTH,
                              }}
                              sxButton={{ bgcolor: publicBlueMain }}
                              onButtonClick={() => {
                                this.openArticle(article.section, article.id);
                              }}
                              minutes={
                                this.state.podpora ? null : article.sideArticles
                              }
                            />
                          </Grid>
                        )}
                      </React.Fragment>
                    ))}
                  </>
                ) : (
                  <>
                    {[0, 1, 2, 3, 4, 5, 6].map((data, index) => (
                      <React.Fragment
                        key={
                          `${
                            this.state.podpora ? "Podpora" : "Blog"
                          }PageSkeletonGridI` + index
                        }
                      >
                        {index === 0 ? (
                          <ArticleHeaderBlogSkeleton
                            image={true}
                            buttonText={true}
                            sxProps={{
                              maxWidth: !this.props.pc ? "100%" : "340px",
                            }}
                          />
                        ) : (
                          <Grid
                            item
                            xs={24}
                            sm={12}
                            sx={{ display: "flex", justifyContent: "flex-end" }}
                            key={
                              `${
                                this.state.podpora ? "Podpora" : "Blog"
                              }PageSkeletonI` + index
                            }
                          >
                            <ArticleTextSkeleton
                              image={true}
                              buttonText={true}
                              sxProps={{
                                maxWidth: !this.props.pc ? "100%" : "340px",
                              }}
                            />
                          </Grid>
                        )}
                      </React.Fragment>
                    ))}
                  </>
                )}

                {!this.state.loading &&
                  this.state.currentSectionData &&
                  this.state.currentSectionData?.count >= ARTICLES_PER_PAGE && (
                    <Grid
                      item
                      xs={24}
                      sm={24}
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <BlogPagination
                        articlesCount={
                          this.state.currentSectionData?.count || 0
                        }
                        page={this.state.currentPage}
                        onPreviousClick={this.onPreviousPageClick}
                        onNextClick={this.onNextPageClick}
                      />
                    </Grid>
                  )}
              </Grid>
            </Box>
          </Container>
        )}
        <MainPageArticles sxProps={{ mb: "0px" }} />
        {this.state.podpora && (
          <Container
            maxWidth="lg"
            sx={{
              mt: { xs: "10px", sm: "90px" },
              mb: { xs: "30px", sm: "50px" },
            }}
          >
            <Grid
              container
              spacing={2}
              columns={24}
              sx={{ px: { xs: "0px", sm: "32px", md: "32px" } }}
            >
              <Grid
                item
                xs={24}
                sm={24}
                md={11}
                lg={11}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  position: "relative",
                  minHeight: { xs: "auto", sm: "420px", md: "480px" },
                  mt: { xs: "30px", sm: "0px", md: "0px" },
                }}
              >
                <ArticleText
                  icon={null}
                  blueText={"Podpora"}
                  title={"Formulář technické podpory"}
                  text={
                    <>
                      Pokud si s něčím nevíte rady nebo potřebujete s něčím
                      pomoci, neváhejte nás kontaktovat. Naši kolegové z
                      technické podpory vám rádi pomohou.
                    </>
                  }
                  sxTitle={{ maxWidth: "280px" }}
                  sxProps={{
                    maxWidth: { xs: "360px", sm: "360px", md: "400px" },
                    pb: { xs: "0px", sm: "40px", md: "0px" },
                  }}
                  sxText={{
                    maxWidth: { xs: "360px", sm: "360px", md: "400px" },
                    fontSize: "13px",
                  }}
                />
              </Grid>
              <Grid
                item
                xs={24}
                sm={24}
                md={13}
                lg={13}
                sx={{
                  display: "flex",
                  justifyContent: {
                    xs: "flex-start",
                    sm: "flex-start",
                    md: "flex-end",
                  },
                  position: "relative",
                  minHeight: { xs: "auto", sm: "420px", md: "480px" },
                  mt: { xs: "30px", sm: "0px", md: "20px" },
                }}
              >
                <ContactForm
                  handleFormSubmit={this.handleFormSubmit}
                  loading={this.state.loading || this.state.loadingBackdrop}
                  buttonText={"Odeslat"}
                  infoText={
                    <>
                      Mimo formulář technické podpory nás můžete kontaktovat
                      také na emailové adrese podpora@profaktura.cz
                    </>
                  }
                />
              </Grid>
            </Grid>
          </Container>
        )}
      </div>
    );
  }
}

export default withContext(withResolution(withRouter(BlogHomePage)));
