import { Tooltip, Typography, styled, tooltipClasses } from "@mui/material";
import React from "react";
import {
  appDarkBlue,
  baseBorder,
  navbarBlue,
} from "../../Design/SourcesJS/themes";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: appDarkBlue,
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 230,
    border: baseBorder,
    borderRadius: "8px",
    margin: "5px",
  },
}));

export default function TooltipBox({
  children,
  text,
  placement = "top",
  show = true,
  index = 0,
  delay = null,
}) {
  if (!show || !text) return <>{children}</>;
  return (
    <HtmlTooltip
      id={"HtmlTooltipCenik" + index}
      enterTouchDelay={delay || 100}
      enterDelay={delay || 400}
      enterNextDelay={delay || 300}
      leaveDelay={0}
      title={
        <React.Fragment>
          <div
            style={{
              padding: "10px",
            }}
          >
            <Typography
              sx={{
                fontSize: "13px",
                fontSize: "Roboto",
                fontWeight: 400,
                color: "white",
              }}
            >
              {text}
            </Typography>
          </div>
        </React.Fragment>
      }
      placement={placement}
    >
      {children}
    </HtmlTooltip>
  );
}
