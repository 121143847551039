import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import CssBaseline from "@mui/material/CssBaseline";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { NAVBAR_HEIGHT, NAVBAR_SPACE } from "../../Classes/Constants";
import {
  publicBlueDark,
  publicBlueMain,
  publicRed,
  zindexPublic,
} from "../../../Design/SourcesJS/publicTheme";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as LogoSvg } from "../../../Design/Graphics/svg/LogoSlon.svg";
import { publicNavbar } from "../../Classes/Navigation";
import BaseButtonPublic from "../Buttons/BaseButtonPublic";
import { publicTheme } from "../../../../App";
import { useMediaQuery } from "@mui/material";
import MobileDrawerMenu from "../../../Core/Menu/MobileDrawerMenu";
import PrettyButton from "../Buttons/Animation/PrettyButton";

export default function PublicNavbar({
  navColor = "rgba(6,81,50,0.9)",
  tryButtonColor = publicRed,
  tryButtonColorHover = publicRed,
  dontHide = false,
  whiteVersion = false,
  threshold = 0,
}) {
  const navigate = useNavigate();
  const sandwichMenu = useMediaQuery(publicTheme.breakpoints.down("md"));
  const mobile = useMediaQuery(publicTheme.breakpoints.only("xs"));
  const [hovered, setHovered] = React.useState(null);
  // Mobile menu
  const [openSandwich, setOpenSandwich] = React.useState(false);
  const [bgTransparent, setBgTransparent] = React.useState(false);

  React.useEffect(() => {
    if (!openSandwich) {
      setTimeout(() => {
        setBgTransparent(false);
      }, 250);
    } else {
      setBgTransparent(true);
    }
  }, [openSandwich]);

  const elevated = useScrollTrigger({
    disableHysteresis: true,
    threshold,
    target: window ? window : undefined,
  });

  const useElevate = dontHide ? true : elevated;

  const Logo = ({ elevated = false }) => {
    const editedIcon = React.cloneElement(<LogoSvg />, {
      style: {
        height: "100%",
        maxWidth: "33px",
        fill: whiteVersion
          ? publicBlueMain
          : elevated
          ? publicBlueMain
          : "white",
      },
    });
    return <>{editedIcon}</>;
  };

  return (
    <Box
      sx={{
        height: `fit-content`,
        width: "100%",
        bgcolor: whiteVersion
          ? useElevate
            ? "rgba(255,255,255,0.9)"
            : "rgba(251,251,251,0.9)"
          : useElevate || bgTransparent
          ? navColor
          : "transparent",
        //opacity: navColor ? "0.9" : "1",
        position: "fixed",
        top: 0,
        zIndex: zindexPublic.navbar,
        boxShadow:
          useElevate || openSandwich
            ? "0px 2px 5px 0px rgba(0,0,0,0.2)"
            : "none",
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          px: "0px !important",
          zIndex: zindexPublic.navbar,
          height: `fit-content`,
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: `${NAVBAR_HEIGHT}px`,
            py: "8px",
            px: NAVBAR_SPACE,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              alignContent: "center",
              mr: "auto",
            }}
          >
            <Box
              component={Link}
              sx={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                textDecoration: "none",
                p: "10px",
                pl: "0px",
                "&:hover": {
                  cursor: "pointer",
                },
              }}
              to="/"
            >
              <Logo elevated={false} />

              <Typography
                variant="logo"
                sx={{
                  ml: "8px",
                  height: "100%",
                  color: whiteVersion ? publicBlueDark : "white",
                }}
              >
                profaktura
              </Typography>
            </Box>
            {!sandwichMenu && (
              <Box
                sx={{
                  height: "26px",
                  ml: "5px",
                  mr: "10px",
                  width: "1px",
                  bgcolor: whiteVersion ? "transparent" : "white",
                }}
              />
            )}

            {!sandwichMenu &&
              publicNavbar.map((nav, index) => (
                <Box
                  key={`NavText${index}`}
                  name={`NavText${index}`}
                  component={Link}
                  sx={{
                    textDecoration: "none",
                    mx: "8px",
                    px: "10px",
                    py: "8px",
                    color: whiteVersion ? publicBlueDark : "white",
                  }}
                  onMouseEnter={(e) => {
                    setHovered(e.target.name);
                  }}
                  onMouseLeave={(e) => {
                    if (e.target.name == hovered) {
                      setHovered(null);
                    }
                  }}
                  onClick={(e) => {
                    if (e.target.name == hovered) {
                      setHovered(null);
                    }
                  }}
                  onContextMenu={(e) => {
                    setHovered(null);
                  }}
                  to={nav.path}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: "bold",
                      lineHeight: "14px",

                      transform:
                        hovered == `NavText${index}`
                          ? "translateY(-3px)"
                          : "translateY(0px)",
                      transitionDuration: "200ms",
                    }}
                  >
                    {nav.name}
                  </Typography>
                </Box>
              ))}
          </Box>

          {!mobile && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                alignContent: "center",
                ml: "auto",
              }}
            >
              <BaseButtonPublic
                scale={1.05}
                outlined
                text="Přihlásit"
                sxProps={{
                  borderColor: whiteVersion ? publicBlueDark : "white",
                  mr: "20px",
                  height: "38px",
                }}
                sxText={{ color: whiteVersion ? publicBlueDark : "white" }}
                onClick={() => {
                  if (!window) return;
                  window.open("https://app.profaktura.cz/login", "_self");
                }}
              />
              <BaseButtonPublic
                scale={1.05}
                sxProps={{ height: "38px" }}
                text={
                  !mobile && sandwichMenu ? "Vyzkoušet" : "Vyzkoušet zdarma"
                }
                color={
                  whiteVersion
                    ? tryButtonColor
                    : elevated
                    ? tryButtonColorHover
                    : tryButtonColor
                }
                onClick={() => {
                  if (!window) return;
                  window.open("https://app.profaktura.cz/registrace", "_self");
                }}
              />
            </Box>
          )}
          {sandwichMenu && (
            <Box sx={{ ml: "10px" }}>
              <MobileDrawerMenu
                whiteVersion={whiteVersion}
                state={openSandwich}
                setState={setOpenSandwich}
              />
            </Box>
          )}
        </Box>
      </Container>
    </Box>
  );
}
