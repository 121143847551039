import {
  doc,
  documentId,
  getDocs,
  query,
  setDoc,
  where,
  writeBatch,
  getDoc,
  limit,
  orderBy,
  getCountFromServer,
  collection,
  startAt,
  startAfter,
} from "firebase/firestore";
import { db } from "../../../../Firebase/init";
import generateUID from "../../../../Firebase/generateUID";
import { ARTICLES_PER_PAGE } from "../BlogHomePage-5";

const litelar = `\uf8ff`;
const sectionDocName = `000000_section_naming`;

export const getBlogSections = async (podpora = false) => {
  return new Promise(async (resolve, reject) => {
    const secRef = doc(db, podpora ? "podpora" : "blog", sectionDocName);
    const docSnap = await getDoc(secRef);

    if (docSnap.exists() && docSnap.data() && docSnap.data().sections) {
      resolve(docSnap.data().sections);
    } else {
      reject(null);
    }
  });
};

export const getSideArticles = async (podpora = false, sectionName, docId) => {
  return new Promise(async (resolve, reject) => {
    const collRef = collection(db, podpora ? "podpora" : "blog");
    let q = query(
      collRef,
      where("section", "==", sectionName),
      where("__name__", "!=", docId),
      limit(3)
    );

    /*q = query(
        collRef,
        where("section", "==", sectionName),
        orderBy("date", "desc"),
        limit(4)
      );*/

    try {
      const querySnapshot = await getDocs(q);
      let docsArr = [];
      querySnapshot.forEach((doc) => {
        if (docsArr.length === 3) return resolve(docsArr);
        if (doc.id != docId) {
          docsArr.push({ ...doc.data(), id: doc.id });
        }
      });
      return resolve(docsArr);
    } catch (error) {
      console.error("Error getting documents: ", error);
      return reject(error);
    }
  });
};

export const getBlogStatistics = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const secRef = doc(db, "blog", sectionDocName);
      const docSnap = await getDoc(secRef);

      const podRef = doc(db, "podpora", sectionDocName);
      const podDocSnap = await getDoc(podRef);

      let finalData = {};
      if (docSnap.exists() && docSnap.data() && docSnap.data().sections) {
        finalData.blogList = docSnap.data().sections;
      }
      if (
        podDocSnap.exists() &&
        podDocSnap.data() &&
        podDocSnap.data().sections
      ) {
        finalData.podporaList = podDocSnap.data().sections;
      }
      resolve(finalData);
    } catch (err) {
      reject(err);
    }
  });
};

export async function getBlogBaseNewest(podpora = false) {
  return new Promise(async (resolve, reject) => {
    const collectionRef = collection(db, podpora ? "podpora" : "blog");

    let q = query(collectionRef, orderBy("date", "desc"), limit(3));

    try {
      let articles = [];

      const querySnapshot = await getDocs(q);

      const newestDocuments = querySnapshot.docs.map(async (qDoc) => {
        let docId = qDoc.id;
        //const htmlRef = doc(db, "blogHtml", `${docId}`);
        //const htmlSnap = await getDoc(htmlRef);
        let docData = qDoc.data();
        //if (htmlSnap.exists()) {
        articles.push({ ...docData, id: docId /*, ...htmlSnap.data()*/ });
        //}
      });

      resolve(articles);
    } catch (error) {
      console.error("Error getting documents: ", error);
      reject(error);
    }
  });
}

// base data or html
export async function getArticleDoc(docId, base = true, podpora = false) {
  return new Promise(async (resolve, reject) => {
    if (!docId) {
      reject("No doc id");
    }
    try {
      const docRef = doc(
        db,
        base
          ? podpora
            ? "podpora"
            : "blog"
          : podpora
          ? "podporaHtml"
          : "blogHtml",
        `${docId}`
      );
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        resolve(docSnap.data());
      } else {
        reject("No article found");
      }
    } catch (err) {
      console.log("Finding article error | ", err);
      reject(err);
    }
  });
}

// get all article data
export async function getArticleFull(docId, podpora = false) {
  return new Promise(async (resolve, reject) => {
    if (!docId) {
      reject("No doc id");
    }
    try {
      const docRef = doc(db, podpora ? "podpora" : "blog", `${docId}`);
      const docHtmlRef = doc(
        db,
        podpora ? "podporaHtml" : "blogHtml",
        `${docId}`
      );
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const docHtmlSnap = await getDoc(docHtmlRef);
        if (docHtmlSnap.exists()) {
          return resolve({
            ...docSnap.data(),
            ...docHtmlSnap.data(),
            uniqueId: docId,
          });
        } else {
          return reject("Invalid data, check html record");
        }
      } else {
        return reject("Doc id not found");
      }
    } catch (err) {
      console.log("Finding article error | ", err);
      reject(err);
    }
  });
}

export async function getBlogArticles(
  section = null,
  lastDoc = null,
  maxArticles = ARTICLES_PER_PAGE,
  podpora = false
) {
  return new Promise(async (resolve, reject) => {
    const queryConstraints = [orderBy("date", "desc"), limit(maxArticles)];
    if (section) {
      queryConstraints.push(where("section", "==", section));
    }
    if (lastDoc) {
      queryConstraints.push(startAfter(lastDoc));
    }

    const q = query(
      collection(db, podpora ? "podpora" : "blog"),
      ...queryConstraints
    );

    try {
      let articles = [];

      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        return reject("No articles");
      } else {
        let lastRef = querySnapshot.docs[querySnapshot.docs.length - 1];

        querySnapshot.forEach(async (qDoc) => {
          let docId = qDoc.id;
          let docData = qDoc.data();
          articles.push({
            ...docData,
            id: docId,
          });
        });

        return resolve({ articles, lastRef });
      }
    } catch (error) {
      console.error("Error getting documents: ", error);
      return reject(error);
    }
  });
}

export async function searchBlogData(docId, title, podpora = false) {
  return new Promise(async (resolve, reject) => {
    if (docId) {
      const docRef = doc(db, podpora ? "podpora" : "blog", `${docId}`);
      const docHtmlRef = doc(
        db,
        podpora ? "podporaHtml" : "blogHtml",
        `${docId}`
      );
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const docHtmlSnap = await getDoc(docHtmlRef);
        if (docHtmlSnap.exists()) {
          return resolve({
            ...docSnap.data(),
            ...docHtmlSnap.data(),
            uniqueId: docId,
          });
        } else {
          return reject("Invalid data, check html record");
        }
      } else {
        return reject("Doc id not found");
      }
    } else if (title) {
      const blogRef = collection(db, podpora ? "podpora" : "blog");
      const q = query(blogRef, where("title", "==", title), limit(1));
      const querySnapshot = await getDocs(q);
      if (querySnapshot.empty) {
        return reject("No docs found");
      }
      querySnapshot.forEach(async (docRef) => {
        const docHtmlRef = doc(
          db,
          podpora ? "podporaHtml" : "blogHtml",
          `${docRef.id}`
        );
        const docHtmlSnap = await getDoc(docHtmlRef);
        if (docHtmlSnap.exists()) {
          return resolve({
            ...docRef.data(),
            ...docHtmlSnap.data(),
            uniqueId: docRef.id,
          });
        } else {
          return reject("Invalid data, check html record");
        }
      });
    } else {
      return reject("No search param found");
    }
  });
}

export async function saveBlogData(data, podpora = false) {
  return new Promise(async (resolve, reject) => {
    if (
      !data.smallTitle ||
      !data.title ||
      !data.imageUrl ||
      !data.text ||
      !data.html ||
      data.html == "<p></p>\n" ||
      !data.section ||
      !data.sideArticles
    ) {
      return reject("You have to fill all the data | Vyplňte všechny položky");
    }

    const { auth } = await import("../../../../Firebase/user/auth");

    if (!auth || !auth.currentUser || !auth.currentUser.emailVerified) {
      return reject("User unauthenticated | Vyžadováno přihlášení");
    }

    try {
      const batch = writeBatch(db);

      const uid = data.uniqueId || generateUID(20);

      const blogRef = doc(db, podpora ? "podpora" : "blog", uid);
      batch.set(blogRef, {
        smallTitle: data.smallTitle,
        title: data.title,
        imageUrl: data.imageUrl,
        text: data.text,
        section: data.section,
        date: new Date(data.date) || new Date(),
        sideArticles: data.sideArticles,
      });

      const blogHtmlRef = doc(db, podpora ? "podporaHtml" : "blogHtml", uid);
      batch.set(blogHtmlRef, {
        html: data.html,
      });

      await batch.commit();

      resolve("Success");
    } catch (err) {
      reject(`Error saving data: ${err}`);
    }
  });
}
