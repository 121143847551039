import { Box, Breadcrumbs, Container, Typography } from "@mui/material";
import React from "react";
import {
  publicBlueDark,
  publicBlueMain,
  zindexPublic,
} from "../../../../Design/SourcesJS/publicTheme";
import { NAVBAR_HEIGHT, NAVBAR_SPACE } from "../../../Classes/Constants";
import { Link } from "react-router-dom";
import { NavigateNext } from "@mui/icons-material";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";

export default function BlogNavbar({
  currentSection = null,
  currentArticle = null,
  sectionsList = null,
  blogChangeUrl,
  podpora = false,
}) {
  const breadcrumbs = [
    <Box
      underline="hover"
      key="1"
      onClick={() => {
        blogChangeUrl(null);
      }}
      style={{
        minHeight: "20px",
        height: "20px",
        display: "flex",
        cursor: "pointer",
        alignItems: "center",
        alignContent: "center",
      }}
    >
      <HomeRoundedIcon
        sx={{ color: publicBlueMain, height: "20px", width: "20px" }}
      />
    </Box>,
    <Box
      underline="hover"
      key="2"
      onClick={() => {
        blogChangeUrl(null);
      }}
      style={{
        color: publicBlueMain,
        textDecoration: "none",
        fontWeight: "bold",
        cursor: "pointer",
        fontSize: "12px",
      }}
    >
      {podpora ? "Podpora" : "Blog"}
    </Box>,
  ];
  if (
    sectionsList &&
    currentSection &&
    Object.keys(sectionsList).includes(currentSection)
  ) {
    breadcrumbs.push(
      <Box
        underline="hover"
        key="3"
        color="inherit"
        onClick={() => {
          if (!currentArticle) return;
          blogChangeUrl(currentSection);
        }}
        style={{
          color: publicBlueDark,
          textDecoration: "none",
          fontWeight: "bold",
          cursor: currentArticle ? "pointer" : "default",
          fontSize: "12px",
        }}
      >
        {sectionsList &&
          currentSection &&
          (Object.keys(sectionsList).includes(currentSection)
            ? sectionsList[currentSection].name
            : "")}
      </Box>
    );
  }

  return (
    <Box
      sx={{
        height: `fit-content`,
        width: "100%",
        bgcolor: "rgba(251,251,251,0.85)",
        position: "static",
        mt: `${NAVBAR_HEIGHT}px`,
        zIndex: zindexPublic.navbar,
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          px: "0px !important",
          zIndex: zindexPublic.navbar,
          height: `fit-content`,
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: `$30px`,
            py: "8px",
            px: NAVBAR_SPACE,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <Breadcrumbs
            separator={
              <NavigateNext fontSize="small" sx={{ color: publicBlueMain }} />
            }
            aria-label="breadcrumb"
          >
            {breadcrumbs}
          </Breadcrumbs>
        </Box>
      </Container>
    </Box>
  );
}
