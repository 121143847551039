import { Box, Grid, Skeleton, Typography } from "@mui/material";
import React from "react";
import SpeedIcon from "@mui/icons-material/Speed";
import { Motion } from "../../../Core/Motion/Motion";
import {
  publicBlueMain,
  publicBorderRadius,
  publicRed,
} from "../../../Design/SourcesJS/publicTheme";

export default function ArticleHeaderBlogSkeleton({
  sxProps = {},
  sxButton = {},
  sxButtonText = {},
  sxTitle = {}, // Mensi font u nekterych
  sxText = {},
  xsBlueText = {},
  icon = true,
  image = true,
  buttonText = false,
}) {
  const boxStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  };

  function SmallIcon({ color }) {
    let editedIcon = React.cloneElement(icon, {
      style: {
        width: "20px",
        height: "20px",
        color: color ? color : publicBlueMain,
        marginRight: "5px",
      },
    });

    return editedIcon;
  }

  return (
    <>
      {image && (
        <Grid
          item
          xs={24}
          sm={12}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Box
            sx={{
              width: "100%",
              pb: "22px",
              maxHeight: "230px",
              height: "auto",
              maxWidth: "340px",
            }}
          >
            <Skeleton
              variant="rounded"
              sx={{
                width: "100%",
                maxHeight: "230px",
                paddingTop: "60%",
                borderRadius: "15px",
              }}
            />
          </Box>
        </Grid>
      )}
      <Grid
        item
        xs={24}
        sm={12}
        sx={{ display: "flex", justifyContent: "flex-end" }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            maxWidth: "340px",
            width: "100%",
          }}
        >
          <Box
            sx={{
              ...boxStyle,
              height: "fit-content",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              alignContent: "center",
            }}
          >
            {icon && (
              <Skeleton
                variant="circular"
                width={20}
                height={20}
                sx={{ mr: "10px", minWidth: "20px", minHeight: "20px" }}
              />
            )}
            <Box
              sx={{
                height: "100%",
                width: "100%",
                position: "relative",
                display: "flex",
                alignItems: "flex-end",
                alignContent: "flex-end",
              }}
            >
              <Skeleton
                variant="text"
                height={24}
                sx={[
                  {
                    position: "absolute",
                    minWidth: "90px",
                    width: "50%",
                    bottom: -2,
                    fontSize: { xs: "15px", sm: "16px" },
                    lineHeight: { xs: "16px", sm: "17px" },
                  },
                  { ...xsBlueText },
                ]}
              />
            </Box>
          </Box>
          <Skeleton
            variant="text"
            sx={[
              {
                mt: "18px",
                fontSize: { xs: "24px", sm: "26px" },
                lineHeight: { xs: "32px", sm: "34px" },
                ...sxTitle,
              },
              image && { fontSize: "26px", lineHeight: "32px" },
            ]}
          />
          <Skeleton
            variant="text"
            sx={[
              {
                mb: "18px",
                fontSize: { xs: "24px", sm: "26px" },
                lineHeight: { xs: "32px", sm: "34px" },
                ...sxTitle,
              },
              image && { fontSize: "26px", lineHeight: "32px" },
            ]}
          />
          <Skeleton variant="text" sx={[{ ...sxText }]} />
          <Skeleton variant="text" sx={[{ ...sxText }]} />
          <Skeleton variant="text" sx={[{ ...sxText }]} />
          {buttonText && (
            <Motion>
              <Skeleton
                variant="circular"
                sx={{
                  mt: "18px",
                  mb: "12px",
                  height: "42px",
                  width: "95px",
                  borderRadius: "42px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  ...sxButton,
                }}
              />
            </Motion>
          )}
        </Box>
      </Grid>
    </>
  );
}
