import React, { lazy } from "react";

// LEGAL
const Podminky = lazy(() => import("./Legal/Podminky"));
const Soukromi = lazy(() => import("./Legal/Soukromi"));
const Cookies = lazy(() => import("./Legal/Cookies"));

// PUBLIC
const PublicMainPage = lazy(() => import("../Public/Pages/PublicMainPage"));

const Fakturace = lazy(() => import("../Public/Pages/SubPages/Fakturace-1"));
const Ucetnictvi = lazy(() => import("../Public/Pages/SubPages/Ucetnictvi-2"));
const Statistika = lazy(() => import("../Public/Pages/SubPages/Statistika-3"));
const Automatizace = lazy(() =>
  import("../Public/Pages/SubPages/Automatizace-4")
);
const BlogHomePage = lazy(() => import("../Public/Pages/Blog/BlogHomePage-5"));
//const ClanekViewBlog = lazy(() => import("../Public/Pages/Blog/ClanekView"));
const Zivnostnici = lazy(() =>
  import("../Public/Pages/SubPages/Zivnostnici-7")
);

const Ucetni = lazy(() => import("../Public/Pages/SubPages/Ucetni-8"));
const UcetniFormPage = lazy(() =>
  import("../Public/Pages/MinorPages/UcetniFormPage")
);

const ProFirmu = lazy(() => import("../Public/Pages/SubPages/ProFirmu-9"));
const Onas = lazy(() => import("../Public/Pages/SubPages/Onas-10"));
//
const CenikPage = lazy(() => import("../Public/Pages/CenikPage/CenikPage"));

// ADMIN
const Login = lazy(() => import("../Public/Pages/Admin/LoginPage"));
const AdminPanel = lazy(() =>
  import("../Public/Pages/Admin/CoreAdmin/AdminPanel")
);
const AdminBlog = lazy(() => import("../Public/Pages/Admin/Blog/AdminBlog"));
const StatistikaPage = lazy(() =>
  import("../Public/Pages/Admin/StatistikaPage")
);

export const publicRoutes = [
  {
    path: "/fakturace",
    element: <Fakturace />,
  },
  {
    path: "/ucetnictvi",
    element: <Ucetnictvi />,
  },
  {
    path: "/statistika",
    element: <Statistika />,
  },
  {
    path: "/automatizace",
    element: <Automatizace />,
  },
  {
    path: "/blog",
    element: <BlogHomePage />,
  },
  {
    path: "/podpora",
    element: <BlogHomePage podpora={true} />,
  },
  {
    path: "/zivnostnici",
    element: <Zivnostnici />,
  },
  {
    path: "/ucetni",
    element: <Ucetni />,
  },
  {
    path: "/ucetni_form",
    element: <UcetniFormPage />,
  },
  {
    path: "/profirmu",
    element: <ProFirmu />,
  },
  {
    path: "/kontakt",
    element: <Onas />,
  },
  {
    path: "/cenik",
    element: <CenikPage />,
  },
  {
    path: "/",
    element: <PublicMainPage />,
  },
  {
    path: "*",
    element: <PublicMainPage />,
  },
];

export const adminRoutes = [
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/blog",
    element: <AdminBlog />,
  },
  {
    path: "/podpora",
    element: <AdminBlog podpora={true} />,
  },
  {
    path: "/statistika",
    element: <StatistikaPage />,
  },
  {
    path: "/panel",
    element: <AdminPanel />,
  },
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "*",
    element: <Login />,
  },
];

export const legalRoutes = [
  {
    path: "/podminky",
    element: <Podminky />,
  },
  {
    path: "/soukromi",
    element: <Soukromi />,
  },
  {
    path: "/cookies",
    element: <Cookies />,
  },
];
