import React from "react";
import { ReactComponent as Visa } from "../../Design/Graphics/svg/Payment/Visa.svg";
import { ReactComponent as Gopay } from "../../Design/Graphics/svg/Payment/Gopay.svg";
import { ReactComponent as Mastercard } from "../../Design/Graphics/svg/Payment/Mastercard.svg";
import { Box } from "@mui/material";

const GopayIcon = () => {
  const editedIcon = React.cloneElement(<Gopay />, {
    style: {
      maxHeight: "14px",
      width: "auto",
      maxWidth: "120px",
      marginRight: "6px",
    },
  });
  return <>{editedIcon}</>;
};

const MastercardIcon = () => {
  const editedIcon = React.cloneElement(<Mastercard />, {
    style: {
      maxHeight: "16px",
      width: "auto",
      maxWidth: "120px",
    },
  });
  return <>{editedIcon}</>;
};

const VisaIcon = () => {
  const editedIcon = React.cloneElement(<Visa />, {
    style: {
      maxHeight: "13px",
      width: "auto",
      maxWidth: "120px",
      marginRight: "10px",
    },
  });
  return <>{editedIcon}</>;
};

export default function PaymentLogos() {
  return (
    <Box sx={{ display: "flex", alignItems: "center", alignContent: "center" }}>
      <GopayIcon />
      <VisaIcon />
      <MastercardIcon />
    </Box>
  );
}
