import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React from "react";
import { baseShadow3 } from "../../../../Design/SourcesJS/themes";
import { publicBlueMain } from "../../../../Design/SourcesJS/publicTheme";
import { NAVBAR_SPACE } from "../../../Classes/Constants";

export default function BlogTopNavigation({
  sectionsList = {},
  currentSection = "",
  onSectionClick,
}) {
  return (
    <div>
      <Box sx={{ mt: "0px", textAlign: "left", px: NAVBAR_SPACE, pb: "50px" }}>
        <FormControl
          fullWidth
          sx={{
            mt: "0px",
            border: "none",
            ".MuiFormControl-root": {
              height: "100%",
            },
            ".MuiInputBase-root": {
              borderRadius: "10px",
              bgcolor: "white",
              height: "55px",
              maxHeight: "55px",
            },
            "& .MuiOutlinedInput-root": {
              height: "55px",
              "& fieldset": {
                border: "none",
                boxShadow: baseShadow3,
              },
              "&:hover fieldset": {
                border: `2px solid ${publicBlueMain}`,
                boxShadow: "1px 2px 5px 0px rgba(115,117,122,0.3)",
              },
              "&.Mui-focused fieldset": {
                border: `2px solid ${publicBlueMain}`,
                boxShadow: "1px 2px 5px 0px rgba(115,117,122,0.4)",
              },
            },
            "&.MuiOutlinedInput-input": {
              backgroundColor: "white",
              background: "none",
            },
            ".MuiInputBase-input::placeholder": {
              color: "black",
            },
            width: "100%",
          }}
        >
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={currentSection || ""}
            MenuProps={{
              disableScrollLock: true,
              sx: {
                "& .MuiButtonBase-root": {
                  backgroundColor: "white",
                  "&:focus": {
                    backgroundColor: "white",
                  },
                  "&:hover": {
                    backgroundColor: "rgb(240,240,240)",
                  },
                },
              },
            }}
            onChange={(e) => {
              if (onSectionClick) {
                onSectionClick(e.target.value);
              }
            }}
            displayEmpty
            sx={{}}
            renderValue={(keyVal) => {
              if (keyVal == "") {
                return "Výběr kategorie";
              } else {
                return `${sectionsList[keyVal].name}`;
              }
            }}
          >
            {Object.keys(sectionsList).map((key, index) => (
              <MenuItem key={"AMenuItemI" + index} value={key}>
                {sectionsList[key].name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </div>
  );
}
