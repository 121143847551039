import React, { Component, Suspense } from "react";
import ErrorPage from "./ErrorPage";
import { ThemeProvider } from "@mui/material";
import { theme } from "../../../App";
import BaseFooter from "../../Core/Footer/BaseFooter";
import LogoNavbar from "../../Core/Navbars/LogoNavbar";
import LoadingBackdrop from "../../Core/Layouts/LoadingBackdrop";

export const PageWrapper = ({ children }) => {
  return (
    <Suspense fallback={<LoadingBackdrop open={true} />}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </Suspense>
  );
};

export default class ErrorHandle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Error Boundary caught an error:", error, errorInfo);
    this.setState({ hasError: true });
  }

  render() {
    const route = this.props.route;

    if (this.state.hasError) {
      return (
        <PageWrapper>
          <LogoNavbar currentPath={route.path} isPublic={route.public} />
          <ErrorPage />
          {!route.noFooter && <BaseFooter path={route.path} />}
        </PageWrapper>
      );
    }
    return this.props.children;
  }
}
