import { Box, Container, Grid } from "@mui/material";
import React from "react";
import { ReactComponent as ErrSvg } from "./error.svg";
import { useNavigate } from "react-router-dom";
import ArticleText from "../../Public/Components/Texts/ArticleTextHeader/ArticleText";

const SvgImage = ({ icon }) => {
  const editedIcon = React.cloneElement(icon, {
    style: {
      height: "100%",
      width: "100%",
    },
  });
  return <>{editedIcon}</>;
};

const base_data = {
  title: "Něco se pokazilo :(",
  text: "Omlouváme se. Zdá se, že se něco pokazilo.",
  blueText: "Chyba",
  svg: <SvgImage icon={<ErrSvg />} />,
  svgProps: { maxWidth: { xs: "180px", sm: "260px", md: "260px" } },
};

export default function ErrorPage({ error_data = base_data }) {
  const navigate = useNavigate();

  return (
    <div>
      <Container maxWidth="lg" sx={{ minHeight: "100vh" }}>
        <Grid
          container
          rowSpacing={4}
          columns={24}
          sx={{
            pt: "120px",
            minHeight: "380",
            px: "20px",
            my: "0px !important",
            display: "flex",
            flexDirection: {
              xs: "column",
              sm: "column",
              md: "row",
            },
          }}
        >
          <Grid
            xs={24}
            sm={24}
            md={14}
            lg={14}
            item
            sx={{
              py: { xs: "40px", sm: "90px" },
              minHeight: { xs: "auto", sm: "auto", md: "300px" },
              display: "flex",
              justifyContent: {
                xs: "flex-start",
                sm: "flex-start",
                md: "flex-start",
              },
              pl: { xs: "0px", sm: "0px", md: "60px" },
              pt: "0px !important",
            }}
          >
            <ArticleText
              icon={null}
              blueText={error_data.blueText}
              title={error_data.title}
              text={error_data.text}
              buttonText={error_data.buttonText}
              onButtonClick={error_data.onButtonClick}
              secColors={error_data.color || undefined}
              sxTitle={{
                fontSize: { xs: "25px", sm: "30px", md: "32px" },
                lineHeight: { xs: "30px", sm: "35px", md: "37px" },
                maxWidth: "350px",
              }}
              sxProps={{ maxWidth: "350px" }}
              sxButton={{
                height: "48px",
                minWidth: "120px",
              }}
              sxText={{ maxWidth: "340px" }}
            />
          </Grid>
          <Grid
            xs={24}
            sm={24}
            md={10}
            lg={10}
            item
            sx={{
              mx: { xs: "8px", sm: "40px", md: "0px" },
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
              alignContent: "center",
              pt: { xs: "40px", sm: "0px" },
            }}
          >
            <Box
              sx={[
                {
                  width: "auto",
                  height: "100%",
                  maxWidth: { xs: "380px", sm: "400px", md: "340px" },
                },
                error_data.svgProps || base_data.svgProps || {},
              ]}
            >
              {error_data.svg || base_data.svg || <></>}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
