import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import SpeedIcon from "@mui/icons-material/Speed";
import { Motion } from "../../../../Core/Motion/Motion";
import {
  publicBlueMain,
  publicBorderRadius,
  publicRed,
} from "../../../../Design/SourcesJS/publicTheme";
import { ArticleButton, ArticleMinutes } from "./contents";

export default function ArticleHeaderBlog({
  sxProps = {},
  sxButton = {},
  sxButtonText = {},
  sxTitle = {}, // Mensi font u nekterych
  sxText = {},
  xsBlueText = {},
  icon = <SpeedIcon />,
  image = null,
  imagePng = null,
  blueText,
  title,
  text,
  buttonText,
  whiteText = false,
  iconColor = null,
  children,
  onButtonClick,
  minutes = null,
}) {
  const boxStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  };

  function SmallIcon({ color }) {
    let editedIcon = React.cloneElement(icon, {
      style: {
        width: "20px",
        height: "20px",
        color: color ? color : publicBlueMain,
        marginRight: "5px",
      },
    });

    return editedIcon;
  }

  return (
    <>
      {image && (
        <Grid
          item
          xs={24}
          sm={12}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Box
            sx={{
              width: "100%",
              maxWidth: "340px",
              ...sxProps,
            }}
          >
            <picture>
              <source srcSet={image} type="image/webp" />
              <img
                style={{
                  width: "100%",
                  minHeight: "280px",
                  maxHeight: "300px",
                  objectFit: "cover",
                  borderRadius: "15px",
                }}
                alt="Článek"
                src={imagePng}
              />
            </picture>
          </Box>
        </Grid>
      )}
      <Grid
        item
        xs={24}
        sm={12}
        sx={{ display: "flex", justifyContent: "flex-end" }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            maxWidth: "340px",
            ...sxProps,
          }}
        >
          <Box
            sx={{
              ...boxStyle,
              height: "fit-content",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              alignContent: "center",
            }}
          >
            {/*icon && <SmallIcon color={whiteText ? "white" : iconColor} />*/}
            <Box
              sx={{
                height: "100%",
                width: "100%",
                position: "relative",
                display: "flex",
                alignItems: "flex-end",
                alignContent: "flex-end",
                overflow: "hidden",
              }}
            >
              <Typography
                variant="smallBlue"
                component="div"
                sx={[
                  {
                    width: "100%",
                    fontSize: { xs: "15px", sm: "16px" },
                    lineHeight: "normal",
                    display: "-webkit-box",
                    WebkitLineClamp: 1,
                    lineClamp: 1,
                    overflow: "hidden",
                    WebkitBoxOrient: "vertical",
                    MozOrient: "vertical",
                  },
                  whiteText && { color: "white" },
                  { ...xsBlueText },
                ]}
              >
                {blueText || ""}
              </Typography>
              {minutes && <ArticleMinutes minutes={minutes} />}
            </Box>
          </Box>
          <Typography
            variant="mainTitle"
            component="h2"
            sx={[
              {
                maxWidth: "300px",
                mb: "18px",
                mt: "18px",
                fontSize: { xs: "24px", sm: "26px" },
                lineHeight: { xs: "32px", sm: "34px" },
                ...sxTitle,
              },
              image && { fontSize: "26px", lineHeight: "32px" },
              whiteText && { color: "white" },
            ]}
          >
            {title || ""}
          </Typography>
          <Typography
            variant="mainText"
            sx={[{ ...sxText }, whiteText && { color: "white" }]}
          >
            {text || ""}
          </Typography>
          {buttonText && (
            <ArticleButton
              buttonText={buttonText}
              sxButtonText={sxButtonText}
              onButtonClick={onButtonClick}
              sxButton={sxButton}
              whiteText={whiteText}
            />
          )}
        </Box>
      </Grid>
    </>
  );
}
