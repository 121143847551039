import * as React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { Box, CircularProgress, Typography } from "@mui/material";
import {
  appDarkBlue,
  appGrey,
  baseShadow,
  navbarBlue,
  textGrey,
} from "../../Design/SourcesJS/themes";
import { Motion } from "../Motion/Motion";

export default function BaseButton(props) {
  const {
    text,
    sxProps,
    leftIcon,
    rightIcon,
    boxShadow,
    loading = false,
    sxText,
    rotate = false,
    type = "submit",
    disabled = false,
    outlined = false,
  } = props;

  const otherProps = { ...props };
  delete otherProps.leftIcon;
  delete otherProps.rightIcon;
  delete otherProps.sxProps;
  delete otherProps.text;
  delete otherProps.boxShadow;
  delete otherProps.loading;
  delete otherProps.sxText;
  delete otherProps.rotate;
  delete otherProps.disabled;
  delete otherProps.outlined;

  const Icon = (props) => {
    const editedIcon = React.cloneElement(props.icon, {
      style: {
        height: "24px",
        width: "24px",
        fill: "white",
      },
    });
    return <>{editedIcon}</>;
  };

  return (
    <Motion scale={1.05}>
      <Button
        component="input"
        sx={[
          {
            height: "60px",
            minWidth: "200px",
            width: "270px",
            maxWidth: "400px",
            borderRadius: "35px",
            mb: "90px",
            display: "flex",
            flexDirection: "row",
            border: outlined ? `2px solid ${appDarkBlue}` : "none",
            //justifyContent: leftIcon ? "left" : rightIcon ? "right" : "center",
            justifyContent: "center",
            backgroundColor: disabled
              ? textGrey
              : outlined
              ? "white"
              : navbarBlue,
            boxShadow: boxShadow || "1px 2px 5px 0px rgba(115,117,122,0.5)",
            ".MuiButton-startIcon": { position: "absolute", left: 20 },
            ".MuiButton-endIcon": { position: "absolute", right: 20 },
            "&:hover": {
              backgroundColor: disabled
                ? textGrey
                : outlined
                ? "white"
                : navbarBlue,
            },
          },
          sxProps ? sxProps : {},
        ]}
        variant="contained"
        startIcon={
          leftIcon && !loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                transition: "transform .2s ease-in-out",
                transform: rotate ? "rotate(90deg)" : "rotate(0deg)",
              }}
            >
              <Icon icon={leftIcon} />
            </Box>
          ) : null
        }
        endIcon={
          rightIcon && !loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                transition: "transform .2s ease-in-out",
                transform: rotate ? "rotate(90deg)" : "rotate(0deg)",
              }}
            >
              <Icon icon={rightIcon} />
            </Box>
          ) : null
        }
        {...otherProps}
      >
        {type === "submit" && (
          <Box sx={{ display: "none" }}>
            <input type="submit"></input>
          </Box>
        )}
        {loading ? (
          <CircularProgress
            sx={{ color: "white" }}
            style={{ width: "28px", height: "28px" }}
          />
        ) : (
          <Typography
            variant="buttonText"
            sx={[
              {
                mt: "1px",
              },
              sxText ? sxText : {},
            ]}
          >
            {text ? text : ""}
          </Typography>
        )}
      </Button>
    </Motion>
  );
}
