import { Box, Container, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { publicBlueMain } from "../../Design/SourcesJS/publicTheme";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";

import { ReactComponent as FacebookIcon } from "../../Design/Public/Footer/IconFacebook.svg";
import { ReactComponent as LinkedInIcon } from "../../Design/Public/Footer/IconLinkedInsvg.svg";
import { ReactComponent as MailIcon } from "../../Design/Public/Footer/IconMail.svg";
import { ReactComponent as PhoneIcon } from "../../Design/Public/Footer/IconPhone.svg";
import { ReactComponent as TwitterIcon } from "../../Design/Public/Footer/IconTwitter.svg";
import { Link } from "react-router-dom";

const NavIcon = ({ icon }) => {
  const editedIcon = React.cloneElement(icon, {
    style: {
      maxHeight: "15px",
      width: "15px",
    },
  });
  return <>{editedIcon}</>;
};

export const extendedNavigation = [
  {
    title: "Vlastnosti",
    nav: [
      {
        name: "Tvorba faktur",
        path: "https://profaktura.cz/podpora?sekce=faktury_a_funkce&clanek=Tvorba_nove_faktury",
      },
      {
        name: "Automatické upomínky",
        path: "https://profaktura.cz/podpora?sekce=faktury_a_funkce&clanek=Automaticke_upominky",
      },
      {
        name: "Vzdálený přístup",
        path: "https://profaktura.cz/podpora?sekce=faktury_a_funkce&clanek=Vzdaleny_pristup_pro_ucetniho",
      },
      {
        name: "Šablony faktur",
        path: "https://profaktura.cz/podpora?sekce=faktury_a_funkce&clanek=Sablony_faktur",
      },
      {
        name: "Export dokladů",
        path: "https://profaktura.cz/podpora?sekce=faktury_a_funkce&clanek=Export_faktur",
      },
      {
        name: "Funkce",
        path: "https://profaktura.cz/podpora?sekce=statistiky_a_srovnani&clanek=Obecne_statistiky_Profaktury",
      },
    ],
  },
  {
    title: "Užitečné",
    nav: [
      { name: "Přihlásit se", path: "https://app.profaktura.cz/login" },
      { name: "Registrovat se", path: "https://app.profaktura.cz/registrace" },
      { name: "Výhody pro účetní", path: "https://profaktura.cz/ucetni" },
      { name: "Výhody pro firmu", path: "https://profaktura.cz/profirmu" },
      { name: "Výhody pro OSVČ", path: "https://profaktura.cz/zivnostnici" },
      { name: "O nás", path: "https://profaktura.cz/kontakt" },
    ],
  },
  {
    title: "Blog",
    nav: [
      {
        name: "Identifikovaná osoba",
        path: "https://profaktura.cz/blog?sekce=dane_a_ucetnictvi&clanek=Identifikovana_osoba_a_co_byste_o_ni_meli_vedet",
      },
      {
        name: "Kontrolní hlášení",
        path: "https://profaktura.cz/blog?sekce=dane_a_ucetnictvi&clanek=Kontrolni_hlaseni_k_DPH",
      },
      {
        name: "Paušální výdaje",
        path: "https://profaktura.cz/blog?sekce=dane_a_ucetnictvi&clanek=Co_to_jsou_pausalni_vydaje_a_jak_funguji",
      },
      {
        name: "Druhy živností",
        path: "https://profaktura.cz/blog?sekce=pravni_zaklady&clanek=Jake_jsou_v_Ceske_republice_druhy_zivnosti",
      },
      {
        name: "Založení s.r.o.",
        path: "https://profaktura.cz/blog?sekce=jak_zacit_podnikat&clanek=Zalozeni_sro_krok_za_krokem",
      },
    ],
  },
  {
    title: "Kontakt",
    nav: [
      {
        name: "Facebook",
        path: "https://www.facebook.com/profile.php?id=61559547917240",
        icon: <NavIcon icon={<FacebookIcon />} />,
      },
      {
        name: "Twitter",
        path: "https://x.com/_Profaktura_",
        icon: <NavIcon icon={<TwitterIcon />} />,
      },
      {
        name: "Linked-in",
        path: "https://www.linkedin.com/company/profaktura-s-r-o/",
        icon: <NavIcon icon={<LinkedInIcon />} />,
      },
      {
        name: "podpora@profaktura.cz",
        path: "mailto:podpora@profaktura.cz",
        icon: <NavIcon icon={<MailIcon />} />,
      },
      {
        name: "737 972 674",
        path: "tel:+420737972674",
        icon: <NavIcon icon={<PhoneIcon />} />,
      },
    ],
  },
];

export default function FooterExtension() {
  const [move, setMove] = useState(null);
  return (
    <div>
      <Box sx={{ minHeight: "410px", bgcolor: "black" }}>
        <Container
          maxWidth="lg"
          sx={{
            pb: { xs: "40px", sm: "40px", md: "20px" },
            pr: "0px !important",
          }}
        >
          <Grid
            container
            columns={24}
            sx={{ pt: { xs: "50px", sm: "50px", md: "110px" }, pl: "20px" }}
          >
            {extendedNavigation.map((navData, i) => (
              <Grid
                key={"GridItem" + i}
                xs={24}
                sm={12}
                md={6}
                lg={6}
                item
                sx={{}}
              >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography
                    sx={{
                      textAlign: "left",
                      fontFamily: "Gordita",
                      fontWeight: "bold",
                      fontSize: "17px",
                      mb: "30px",
                      pt: { xs: "60px", sm: "60px", md: "30px" },
                    }}
                  >
                    {navData.title}
                  </Typography>
                  {navData.nav.map((navLinks, j) => (
                    <Box
                      key={"NavDataBox" + j}
                      sx={{ display: "flex", flexDirection: "row", mb: "14px" }}
                    >
                      <Box
                        component={Link}
                        to={navLinks.path}
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          alignContent: "center",
                          width: "100%",
                          mr: { xs: "33px", sm: "60px" },
                          textDecoration: "none",
                        }}
                        onMouseEnter={() => {
                          setMove({ i, j });
                        }}
                        onMouseLeave={() => {
                          if (move?.i === i && move?.j === j) {
                            setMove(null);
                          }
                        }}
                      >
                        {navLinks.icon && (
                          <div
                            style={{
                              marginRight: "20px",
                              height: "20px",
                            }}
                          >
                            {navLinks.icon}
                          </div>
                        )}
                        <Typography
                          sx={{
                            textAlign: "left",
                            fontSize: "13px",
                            color: "#E8E8E8",
                            "&:hover": {
                              color: "white",
                            },
                            width: "fit-content",
                            transition: "transform 0.2s ease",
                            transform:
                              move?.i === i && move?.j === j
                                ? "translateX(5px)"
                                : "translateX(0px)",
                          }}
                        >
                          {navLinks.name}
                        </Typography>
                        <Box sx={{ ml: "auto", pl: "3px" }}>
                          <ArrowForwardIosRoundedIcon
                            sx={{ maxHeight: "10px", color: publicBlueMain }}
                          />
                        </Box>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
      <div style={{ width: "100%", height: "1px", backgroundColor: "grey" }} />
    </div>
  );
}
