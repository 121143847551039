import { Box, Typography, useScrollTrigger } from "@mui/material";
import React, { useEffect } from "react";
import {
  publicBlueDark,
  publicBlueMain,
} from "../../../../Design/SourcesJS/publicTheme";
import { NAVBAR_HEIGHT, NAVBAR_SPACE } from "../../../Classes/Constants";
import { useScroll, useSpring } from "framer-motion";

export default function BlogSideNavigation({
  podpora = false,
  sectionsList = null,
  sortedSectionsList = null,
  currentSection,
  onSectionClick,
  articlesCount = 1,
}) {
  let lines = Math.ceil((articlesCount + (articlesCount % 2 == 0 ? 1 : 0)) / 2);
  let threshold = 360;

  for (let i = 0; i < lines - 1; i++) {
    if (i != 0) {
      threshold += 545;
    }
  }

  if (articlesCount == 1) {
    threshold = 0;
  }

  const diff = 100;

  const elevated = useScrollTrigger({
    disableHysteresis: true,
    threshold,
    target: window ? window : undefined,
  });

  const startMoving = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
    target: window ? window : undefined,
  });

  const NavText = ({ keyName, home = false }) => {
    return (
      <Typography
        sx={{
          fontFamily: "Roboto",
          fontSize: "12px",
          fontWeight: "bold",
          maxWidth: "157px",
          color:
            currentSection == keyName || (!currentSection && home)
              ? "white"
              : publicBlueDark,
          //mt: currentSection == keyName ? "0px" : "5px",
          width: "auto",
          p:
            currentSection == keyName || (!currentSection && home)
              ? "0px"
              : "5px",
          pl:
            currentSection == keyName || (!currentSection && home)
              ? "18px"
              : "5px",
          pr:
            currentSection == keyName || (!currentSection && home)
              ? "18px"
              : "5px",
        }}
      >
        {!home ? sectionsList[keyName].name : "Všechny články"}
      </Typography>
    );
  };

  /*
  position: elevated ? "absolute" : "fixed",
  top: elevated
    ? `${threshold + diff - 5}px`
    : `${NAVBAR_HEIGHT * 2 + diff}px`,
  */

  return (
    <div>
      {sectionsList && (
        <Box
          sx={{
            position: elevated
              ? "absolute"
              : startMoving
              ? "fixed"
              : "absolute",
            top: elevated
              ? `${threshold - 6}px`
              : startMoving
              ? `94px`
              : `94px`,
            //left: NAVBAR_SPACE,
            ml: NAVBAR_SPACE,
            textAlign: "left",
            maxWidth: "190px",
            width: "auto",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Gordita",
              fontSize: "16px",
              fontWeight: "bold",
              color: publicBlueDark,
              pb: "15px",
            }}
          >
            Kategorie
          </Typography>
          {/* "ALL" SECTION */}
          {!podpora && (
            <Box
              key={"SideNavbarBlogI000"}
              sx={{
                cursor: "pointer",
                width: "fit-content",
                mt: "6px",
              }}
              onClick={() => {
                onSectionClick(null, true);
              }}
            >
              {!currentSection ? (
                <Box
                  sx={{
                    minWidth: "140px",
                    maxWidth: "157px",
                    height: "33px",
                    borderRadius: "20px",
                    bgcolor: publicBlueMain,
                    ml: "-15px",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    alignContent: "center",
                    textAlign: "left",
                  }}
                >
                  <NavText keyName={true} home={true} />
                </Box>
              ) : (
                <NavText keyName={false} home={true} />
              )}
            </Box>
          )}
          {/* OTHER SECTIONS */}
          {sortedSectionsList.map((keyName, index) => (
            <Box
              key={"SideNavbarBlogI" + index}
              sx={{
                cursor: "pointer",
                width: "fit-content",
                mt: "6px",
              }}
              onClick={() => {
                onSectionClick(keyName);
              }}
            >
              {currentSection == keyName ? (
                <Box
                  sx={{
                    minWidth: "140px",
                    maxWidth: "157px",
                    height: "33px",
                    borderRadius: "20px",
                    bgcolor: publicBlueMain,
                    ml: "-15px",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    alignContent: "center",
                    textAlign: "left",
                  }}
                >
                  <NavText keyName={keyName} />
                </Box>
              ) : (
                <NavText keyName={keyName} />
              )}
            </Box>
          ))}
        </Box>
      )}
    </div>
  );
}
