import { useMediaQuery } from "@mui/material";
import { publicTheme } from "../../../../../App";

export const withResolution = (Component) => {
  const Wrapper = (props) => {
    const mobile = useMediaQuery(publicTheme.breakpoints.only("xs"));
    const tablet = useMediaQuery(publicTheme.breakpoints.only("sm"));
    const pc = useMediaQuery(publicTheme.breakpoints.up("md"));

    return <Component mobile={mobile} tablet={tablet} pc={pc} {...props} />;
  };

  return Wrapper;
};
