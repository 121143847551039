import { appDarkBlue, navbarBlue } from "./themes";

export const publicBlueMain = "#0265F4";
export const publicBlueDark = "#001949";
export const publicGreyText = "#73757A";
export const publicGreyDivider = "#D9D9D9";
export const publicRed = "#F54F64";
export const publicPurple = "#9F3EED";
export const publicWhite = "#FFF";

export const publicShadow = "1px 2px 5px 0px rgba(115,117,122,0.3)";

export const publicBaseBorder = "1px solid #D9D9D9";
export const publicBlueBorder = `2px solid ${publicBlueMain}`;
export const publicErrorBorder = `2px solid ${publicRed}`;

export const publicBorderRadius = "10px";

export const zindexPublic = {
  header: 9900,
  guideOverlay: 9998,
  menuOverlay: 9999,
  navbar: 10000,
  overlay: 10001,
  maxOverlay: 10020,
};

export const PUBLIC_SCREEN_SIZE = 24;

export const TEXT_NOT_SELECTABLE = {
  WebkitUserSelect: "none",
  WebkitTouchCallout: "none",
  msTouchSelect: "none",
  MozUserSelect: "none",
  msUserSelect: "none",
  userSelect: "none",
};

export const HIGHLIGHT_REMOVED = {
  WebkitTapHighlightColor: "transparent",
};

export const NOT_SELECTABLE = {
  ...TEXT_NOT_SELECTABLE,
  ...HIGHLIGHT_REMOVED,
};

const publicThemeFile = () => {
  return {
    palette: {
      primary: {
        main: `${publicBlueMain}`,
        light: `${publicBlueMain}`,
        dark: `${publicBlueDark}`,
        contrastText: "#fff",
      },
      secondary: {
        light: "#ff6333",
        main: "#ff3d00",
        dark: "#b22a00",
        contrastText: "#fff",
      },
      error: {
        light: publicRed,
        main: publicRed,
        dark: publicRed,
      },
    },
    components: {
      MuiTypography: {
        defaultProps: {
          variantMapping: {
            logo: "p",
            smallBlue: "p",
          },
        },
        styleOverrides: {
          root: ({ ownerState }) => ({
            textTransform: "none",
            /* CORE */
            ...(ownerState.variant === "title" && {
              fontSize: "32px",
              fontWeight: 700,
              fontFamily: "Gordita",
              lineHeight: "38px",
              color: navbarBlue,
            }),
            ...(ownerState.variant === "inputTitle" && {
              fontSize: "13px",
              fontWeight: 700,
              fontFamily: "Gordita",
              lineHeight: "13px",
              color: appDarkBlue,
            }),
            ///
            ...(ownerState.variant === "logo" && {
              fontSize: "17px",
              fontFamily: "Rawline",
              fontWeight: 900,
              textTransform: "uppercase",
              lineHeight: "22px",
              color: "#fff",
            }),
            ...(ownerState.variant === "h1" && {
              fontSize: "22px",
            }),
            ...(ownerState.variant === "body1" && {
              fontSize: "13px",
            }),
            ...(ownerState.variant === "error" && {
              color: publicRed,
              fontWeight: "bold",
              fontSize: "14px",
              lineHeight: "14px",
            }),
            // MAIN TEXTS LAYOUT
            ...(ownerState.variant === "smallBlue" && {
              fontFamily: "Gordita",
              fontWeight: "bold",
              color: publicBlueMain,
              fontSize: "16px",
              lineHeight: "20px",
              textAlign: "left",
              display: "flex",
              alignContent: "center",
              //alignSelf: "center",
            }),
            ...(ownerState.variant === "mainTitle" && {
              fontFamily: "Gordita",
              fontWeight: "bold",
              color: publicBlueDark,
              fontSize: "30px",
              lineHeight: "38px",
              textAlign: "left",
            }),
            ...(ownerState.variant === "mainText" && {
              color: publicGreyText,
              fontSize: "14px",
              lineHeight: "22px",
              textAlign: "left",
              fontWeight: 500,
            }),
            ...(ownerState.variant === "buttonText" && {
              color: publicWhite,
              fontSize: "12px",
              lineHeight: "14px",
              fontWeight: "bold",
            }),
          }),
        },
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
          //disableFocusRipple: true,
        },
      },
      MuiButton: {
        styleOverrides: {
          root: ({ ownerState }) => ({}),
        },
      },
      MuiGrid: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            padding: 0,
          }),
        },
      },
    },
    typography: {
      useNextVariants: true,
    },
  };
};
export default publicThemeFile;
