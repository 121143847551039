import React from "react";
import Helmet from "../../../Routes/Helmet/Helmet";

export default function HelmetBlog({ podpora, article }) {
  function getBaseData() {
    if (!article || !article?.title || !article?.text) {
      if (podpora) {
        return {
          path: "/podpora",
          title: "Podpora | Profaktura.cz | Jednoduchá fakturace pro každého",
          description:
            "Zorientujte se v Profaktuře i podnikání | Přečtěte si návody týkající se jednotlivých funkcí Profaktury | Prohlubte své znalosti v podnikání a daňové i účetní problematice.",
        };
      } else {
        return {
          path: "/blog",
          title: "Blog | Články | Fakturace bez starostí | Profaktura.cz",
          description:
            "Prohlubte své znalosti v podnikání a daňové, účetní i právní problematice | Zorientujte se v Profaktuře i podnikání | Profaktura.cz | Jednoduchá fakturace pro každého",
        };
      }
    } else {
      return {
        title: `${Boolean(podpora) ? `Podpora` : `Blog`} | Profaktura.cz | ${
          article.title
        }`,
        description: `${article.text}`,
      };
    }
  }

  return <Helmet baseData={getBaseData()} />;
}
