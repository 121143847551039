export const helmetData = [
  {
    path: "/",
    title: "Profaktura.cz | Jednoduchá fakturace pro každého",
    description:
      "Tvořte své faktury rychleji než kdy dřív | Zdarma a bez závazků | Zálohujte své faktury online a využijte mnoha automatických funkcí | Profaktura.cz | Fakturace bez starostí",
  },
  {
    path: "/fakturace",
    title:
      "Profaktura.cz | Urychlíme vznik i zaplacení faktury | Fakturujte jednoduše online",
    description:
      "S námi své doklady vyřešíte rychleji | Profesionální faktury | Údaje za vás vyplníme z veřejných rejstříků | Každou z faktur můžete vytvořit i odeslat do minuty.",
  },
  {
    path: "/ucetnictvi",
    title:
      "Profaktura.cz | Fakturační program, který vám ulehčí spolupráci s účetní | Online",
    description:
      "Zjednodušíme vám jak tvorbu dokladů, tak jejich cestu k účetní | Exportujte a odešlete všechny své doklady během minuty | Už žádný zmatek v souborech | Získejte možnost spravovat vše odkudkoliv",
  },
  {
    path: "/statistika",
    title:
      "Užitečné statistiky a přehledné grafy | Profaktura.cz | Jednoduchý fakturační program",
    description:
      "Získejte jasný přehled o tom, kolik jste komu vyfakturovali | Mějte jasno v tom, kolik peněz se k vám reálně dostalo | Každého partnera můžete mít pod lupou",
  },
  {
    path: "/automatizace",
    title: "Automatické funkce | Profaktura.cz | Jednoduchý fakturační program",
    description:
      "Ušetřete čas pomocí automatických funkcí, jako jsou automatické upomínky pro neplatící odběratele, řada emailových šablon nebo automatické načítání fakturačních údajů vašich odběratelů.",
  },
  {
    path: "/zivnostnici",
    title:
      "Jednoduchá fakturace pro každého živnostníka | Profaktura.cz | Online fakturace",
    description:
      "Urychlete tvorbu svých faktur s Profakturou| Prezentujte se fakturami na úrovni | Zálohujte své faktury online a využívejte mnoha automatických funkcí | Profaktura.cz | Fakturace bez starostí",
  },
  {
    path: "/ucetni",
    title:
      "Profaktura.cz | Efektivní fakturace | Ulehčete si spolupráci s vaším klientem",
    description:
      "Zjednodušíme vám jak tvorbu dokladů, tak komunikaci s klienty | Exportujte všechny doklady během minuty | Už žádný zmatek v souborech klienta| Získejte možnost spravovat vše na jednom místě",
  },
  {
    path: "/ucetni_form",
    title:
      "Profaktura.cz | Efektivní fakturace | Ulehčete si spolupráci s vaším klientem",
    description:
      "Zjednodušíme vám jak tvorbu dokladů, tak komunikaci s klienty | Exportujte všechny doklady během minuty | Už žádný zmatek v souborech klienta| Získejte možnost spravovat vše na jednom místě",
  },
  {
    path: "/profirmu",
    title:
      "Profaktura | Ideální řešení pro vaši firmu | Fakturujte efektivně a online",
    description:
      "Urychlete tvorbu svých faktur s Profakturou | Prezentujte se fakturami na úrovni | Zálohujte své faktury online a využijte mnoha automatických funkcí | Profaktura.cz | Fakturace bez starostí",
  },
  {
    path: "/kontakt",
    title: "Kontakt | Obchodní podmínky | Profaktura s.r.o.| Profaktura.cz",
    description:
      "Profaktura s.r.o | všechna práva vyhrazena | podpora@profaktura.cz | Högrova 2862/2 | Královo Pole | 612 00 Brno | IČO: 21594830 | DIČ: CZ21594830 |  Fakturace bez starostí",
  },
  {
    path: "/cenik",
    title: "Ceník | Profaktura.cz | Efektivní fakturace online",
    description:
      "S námi své doklady vytvoříte, odešlete a můžete mít zaplaceny rychleji | Profesionální faktury | Údaje za vás vyplníme z veřejných rejstříků | Každou z faktur můžete vytvořit i odeslat do minuty",
  },
];
