import * as React from "react";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { appDarkBlue, navbarBlue } from "../../Design/SourcesJS/themes";

export default function InfoIcon({
  text = "",
  filled = true,
  iconColor = navbarBlue,
  placement = "top",
  sxIcon = {},
  sxButton = {},
  open = undefined, // if true = force open
  sxFont = {},
}) {
  return (
    <Tooltip
      //enterTouchDelay={100}
      open={open ? open : undefined}
      title={
        text ? (
          <span style={{ fontSize: "13px", ...sxFont }}>{text}</span>
        ) : undefined
      }
      placement={placement}
    >
      {/* empty div was here */}
      <IconButton sx={{ ...sxButton }}>
        {filled ? (
          <InfoRoundedIcon sx={{ fill: iconColor, ...sxIcon }} />
        ) : (
          <InfoOutlinedIcon sx={{ fill: iconColor, ...sxIcon }} />
        )}
      </IconButton>
    </Tooltip>
  );
}
