import { Box, Typography } from "@mui/material";
import React from "react";
import SpeedIcon from "@mui/icons-material/Speed";
import {
  publicBlueMain,
  publicBorderRadius,
  publicRed,
} from "../../../../Design/SourcesJS/publicTheme";
import { Motion } from "../../../../Core/Motion/Motion";
import TooltipBox from "../../../../Core/Base/TooltipBox";
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import { appGrey, textGrey } from "../../../../Design/SourcesJS/themes";
import { ArticleButton, ArticleMinutes } from "./contents";

export default function ArticleText({
  sxProps = {},
  sxButton = {},
  sxButtonText = {},
  sxTitle = {}, // Mensi font u nekterych
  sxText = {},
  xsBlueText = {},
  icon = <SpeedIcon />,
  image = null,
  imagePng = null,
  blueText,
  title,
  text,
  buttonText,
  whiteText = false,
  iconColor = null,
  children,
  onButtonClick,
  minutes = null,
}) {
  const boxStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  };

  function SmallIcon({ color }) {
    let editedIcon = React.cloneElement(icon, {
      style: {
        width: "20px",
        height: "20px",
        color: color ? color : publicBlueMain,
        marginRight: "5px",
      },
    });

    return editedIcon;
  }

  return (
    <Box
      sx={{
        ...boxStyle,
        pt: "20px",
        maxWidth: "340px",
        ...sxProps,
      }}
    >
      {image && (
        <Box sx={{ width: "100%", mb: "18px" }}>
          <picture>
            <source srcSet={image} type="image/webp" />
            <Box
              component="img"
              sx={{
                width: "100%",
                borderRadius: "15px",
                objectFit: "cover",
                minHeight: { xs: "300px", sm: "220px", md: "200px" },
                maxHeight: { xs: "300px", sm: "220px", md: "200px" },
              }}
              alt="Článek"
              src={imagePng}
              //loading="lazy"
            />
          </picture>
        </Box>
      )}
      <Box
        sx={{
          ...boxStyle,
          height: "fit-content",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          alignContent: "center",
        }}
      >
        {/*icon && <SmallIcon color={whiteText ? "white" : iconColor} />*/}
        <Box
          sx={{
            height: "100%",
            width: "100%",
            position: "relative",
            display: "flex",
            alignItems: "flex-end",
            alignContent: "flex-end",
            overflow: "hidden",
          }}
        >
          <Typography
            variant="smallBlue"
            component="div"
            sx={[
              {
                width: "100%",
                fontSize: { xs: "15px", sm: "16px" },
                lineHeight: "normal",
                display: "-webkit-box",
                WebkitLineClamp: 1,
                lineClamp: 1,
                overflow: "hidden",
                WebkitBoxOrient: "vertical",
                MozOrient: "vertical",
              },
              whiteText && { color: "white" },
              { ...xsBlueText },
            ]}
          >
            {blueText || ""}
          </Typography>
          {minutes && <ArticleMinutes minutes={minutes} />}
        </Box>
      </Box>
      <Typography
        variant="mainTitle"
        component="h2"
        sx={[
          {
            maxWidth: "300px",
            mb: "18px",
            mt: "18px",
            fontSize: { xs: "24px", sm: "26px" },
            lineHeight: { xs: "32px", sm: "34px" },
            ...sxTitle,
          },
          image && { fontSize: "26px", lineHeight: "32px" },
          whiteText && { color: "white" },
        ]}
      >
        {title || ""}
      </Typography>
      <Typography
        variant="mainText"
        sx={[{ ...sxText }, whiteText && { color: "white" }]}
      >
        {text || ""}
      </Typography>
      {buttonText && (
        <ArticleButton
          buttonText={buttonText}
          sxButtonText={sxButtonText}
          onButtonClick={onButtonClick}
          sxButton={sxButton}
          whiteText={whiteText}
        />
      )}
      {children && children}
    </Box>
  );
}
