import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

export const withRouter = (Component) => {
  const Wrapper = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { param } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();

    return (
      <Component
        location={location}
        navigate={navigate}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        param={param}
        {...props}
      />
    );
  };

  return Wrapper;
};
