import { Box, Typography } from "@mui/material";
import React from "react";
import TooltipBox from "../../../../Core/Base/TooltipBox";
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import { textGrey } from "../../../../Design/SourcesJS/themes";
import { Motion } from "../../../../Core/Motion/Motion";
import { publicRed } from "../../../../Design/SourcesJS/publicTheme";
import { Link } from "react-router-dom";

export const ArticleMinutes = ({ minutes }) => (
  <TooltipBox
    delay={0}
    text={"Průměrný počet minut potřebný k přečtení článku"}
  >
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignContent: "center",
        alignItems: "center",
        cursor: "pointer",
      }}
    >
      <AccessTimeRoundedIcon
        sx={{
          fill: textGrey,
          height: "20px",
          width: "20px",
          mr: "7px",
        }}
      />
      <Typography
        sx={{
          fontFamily: "Roboto",
          fontSize: "14px",
          color: textGrey,
          fontWeight: "bold",
          whiteSpace: "nowrap",
          mr: "5px",
        }}
      >{`${minutes} min.`}</Typography>
    </Box>
  </TooltipBox>
);

export const ArticleButton = ({
  buttonText,
  sxButtonText = {},
  onButtonClick,
  sxButton = {},
  whiteText,
}) => (
  <Motion>
    <Box
      sx={{
        mt: "28px",
        bgcolor: publicRed,
        height: "42px",
        width: "fit-content",
        borderRadius: "42px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        ...sxButton,
      }}
      onClick={() => {
        if (onButtonClick) {
          onButtonClick();
        }
      }}
    >
      <Typography
        sx={[{ mx: "28px", ...sxButtonText }, whiteText && { color: "white" }]}
        variant="buttonText"
      >
        {buttonText}
      </Typography>
    </Box>
  </Motion>
);
