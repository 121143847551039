import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { withRouter } from "../../Routes/withRouter";
import { ReactComponent as LogoSvg } from "../../Design/Graphics/svg/LogoSlon.svg";
import {
  Box,
  Container,
  Divider,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  appDarkBlue,
  FOOTER_HEIGHT,
  navbarBlue,
  subTitleBlue,
} from "../../Design/SourcesJS/themes";
import PaymentLogos from "../Base/PaymentLogos";
import LockIcon from "@mui/icons-material/Lock";
import { theme } from "../../../App";
import { Motion } from "../Motion/Motion";
import FooterExtension from "./FooterExtension";
import CookieBar from "../../Routes/Legal/CookieBar";

const Logo = () => {
  const editedIcon = React.cloneElement(<LogoSvg />, {
    style: {
      height: "100%",
      maxWidth: "33px",
      fill: navbarBlue,
    },
  });
  return <>{editedIcon}</>;
};

const Lock = () => {
  const editedIcon = React.cloneElement(<LockIcon />, {
    style: {
      height: "12px",
      maxWidth: "12px",
      fill: "white",
    },
  });
  return <>{editedIcon}</>;
};

function BaseFooter({ extended = false, admin = false }) {
  const [cookieOpen, setCookieOpen] = useState(false);
  const mobile = useMediaQuery(theme.breakpoints.only("xs"));
  let navigate = useNavigate();

  return (
    <footer>
      {!admin && <CookieBar open={cookieOpen} setOpen={setCookieOpen} />}
      {extended && <FooterExtension />}
      <Box
        sx={{
          width: "100%",
          height: `${FOOTER_HEIGHT}px`,
          bgcolor: "black",
          position: "static",
          bottom: 0,
          boxShadow: "0px -2px 5px 0px rgba(115,117,122,0.3)",
        }}
      >
        <Container maxWidth="lg">
          <Box
            sx={{
              width: "100%",
              height: "100%",
              p: "20px",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <Box
                component={Link}
                sx={{
                  marginRight: "auto",
                  display: "flex",
                  justifyContent: "center",
                  textDecoration: "none",
                  width: "fit-content",
                  p: "5px",
                  pl: "0px",
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
                onClick={() => {
                  navigate("/");
                }}
                to="/"
              >
                <Logo />
                <Typography
                  variant="logo"
                  sx={{
                    ml: "8px",
                    height: "100%",
                    display: { xs: "inherit", sm: "inherit" },
                  }}
                >
                  profaktura
                </Typography>
              </Box>
              <Box sx={{ ml: "auto" }}>
                <Motion>
                  <Box
                    sx={{
                      width: "fit-content",
                      height: "22px",
                      bgcolor: navbarBlue,
                      display: "flex",
                      flexDirection: "row",
                      alignContent: "center",
                      alignItems: "center",
                      borderRadius: "12px",
                      p: "4px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setCookieOpen(true);
                    }}
                  >
                    <Lock />
                    {!mobile && (
                      <Typography
                        sx={{ mx: "15px", fontSize: "11px", mt: "1px" }}
                      >
                        Cookies policy
                      </Typography>
                    )}
                  </Box>
                </Motion>
              </Box>
            </Box>
            <Box
              sx={{
                mt: "30px",
                width: "100%",
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  height: "100%",
                  //display: { xs: "none", sm: "flex", md: "flex" },
                }}
              >
                <Typography
                  variant="footerText"
                  sx={{
                    fontWeight: "normal",
                    fontSize: "10px",
                    lineHeight: "15px",
                    textAlign: "left",
                    mr: "20px",
                  }}
                >
                  {mobile ? (
                    <>© {new Date().getFullYear()} Profaktura&nbsp;s.r.o</>
                  ) : (
                    `© ${new Date().getFullYear()} Profaktura s.r.o | všechna práva
                vyhrazena | podpora@profaktura.cz | Högrova 2862/2 | Královo
                Pole | 612 00 Brno | IČO: 21594830 | DIČ: CZ21594830`
                  )}
                </Typography>
              </Box>
              <Box sx={{ ml: "auto" }}>
                <PaymentLogos />
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </footer>
  );
}

export default withRouter(BaseFooter);
