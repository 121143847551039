import React from "react";
import { Motion } from "../../../Core/Motion/Motion";
import { Box, Typography } from "@mui/material";
import { publicBlueMain } from "../../../Design/SourcesJS/publicTheme";

export default function BaseButtonPublic({
  sxProps = {},
  sxText = {},
  text = "",
  outlined = false,
  color = publicBlueMain,
  onClick,
  scale = 1.08,
}) {
  return (
    <Motion scale={scale}>
      <Box
        sx={{
          bgcolor: outlined ? "transparent" : color,
          border: `3px solid ${color}`,
          height: "42px",
          width: "fit-content",
          borderRadius: "42px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
          ...sxProps,
        }}
        onClick={() => {
          if (onClick) onClick();
        }}
      >
        <Typography variant="buttonText" sx={{ mx: "25px", ...sxText }}>
          {text}
        </Typography>
      </Box>
    </Motion>
  );
}
