import React from "react";
import { Helmet as RHelmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import { helmetData } from "./helmetData";

const excludedPaths = ["/podpora", "/blog"];

function getHelmetData(path) {
  const hPath = path || "/";

  if (excludedPaths.includes(hPath)) return false;

  const finalHData = helmetData;
  for (let i = 0; i < finalHData.length; i++) {
    if (finalHData[i].path === hPath) {
      return finalHData[i];
    }
  }
  return finalHData[0];
}

export default function Helmet({ children, baseData = false }) {
  const location = useLocation();
  const data = baseData || getHelmetData(location.pathname);
  if (!data) return children;

  return (
    <>
      <RHelmet>
        <title>{data.title}</title>
        <meta name="description" content={data.description} />
        <meta property="og:title" content={data.title} />
        <meta property="og:description" content={data.description} />
        <meta name="twitter:title" content={data.title} />
        <meta name="twitter:description" content={data.description} />
      </RHelmet>
      {children}
    </>
  );
}
