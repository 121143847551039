import React from "react";
import { Box, Container, Grid, useMediaQuery } from "@mui/material";
import BgLayout from "../BgLayout";
import ArticleText from "../../Texts/ArticleTextHeader/ArticleText";
import {
  publicBlueDark,
  publicBlueMain,
} from "../../../../Design/SourcesJS/publicTheme";
import { NAVBAR_SPACE } from "../../../Classes/Constants";
import { publicTheme } from "../../../../../App";
import { useNavigate } from "react-router-dom";

import background2 from "../../../../Design/Public/Pages/MainPage/background2.svg";

export default function MainPageArticles({
  bgImage = background2,
  sxProps = {},
}) {
  const mobile = useMediaQuery(publicTheme.breakpoints.only("xs"));
  const tablet = useMediaQuery(publicTheme.breakpoints.only("sm"));
  const navigate = useNavigate();

  return (
    <Box sx={{ position: "relative", my: "80px", mb: "90px", ...sxProps }}>
      <Container
        maxWidth="lg"
        sx={{
          px: "0px !important",
        }}
      >
        <BgLayout
          image={bgImage}
          sxContainer={{
            minHeight: { xs: "1100px", sm: "1060px", md: "680px" },
          }}
          sxProps={{
            minHeight: { xs: "100%", sm: "1060px", md: "680px" },
            minWidth: { xs: "880px", sm: "1000px", md: "800px" },
          }}
          sxChild={{}}
        >
          <Grid
            container
            columns={24}
            columnSpacing={4}
            sx={{
              py: "80px",
              mt: { xs: "0px", sm: "0px", md: "60px" },
              pl: NAVBAR_SPACE,
              pr: "24px",
              ml: "-32px !important",
            }}
          >
            <Grid item xs={24} sm={24} md={8}>
              <ArticleText
                whiteText={true}
                blueText={"Fakturace pro živnostníky"}
                title={"Jste živnostník?"}
                text={
                  "Každý živnostník má starostí nad hlavu. Proto chceme živnostníkům jejich podnikání maximálně ulehčit. Výhody pro živnostníky naleznete zde."
                }
                buttonText={"Ukázat výhody"}
                sxButton={{
                  bgcolor: publicBlueMain,
                  mt: { xs: "30px", sm: "30px", md: "50px" },
                  mb: { xs: "60px", sm: "50px" },
                }}
                sxProps={{
                  maxWidth: { xs: "550px", sm: "550px", md: "340px" },
                }}
                sxText={{ maxWidth: { xs: "550px", sm: "550px", md: "260px" } }}
                onButtonClick={() => {
                  navigate("/zivnostnici");
                }}
              />
            </Grid>
            <Grid item xs={24} sm={24} md={8}>
              <ArticleText
                whiteText={true}
                blueText={"Fakturace pro firmy"}
                title={"Máte firmu?"}
                text={
                  "Řídit firmu je vždy výzva. S Profakturou vám cestu usnadníme prostřednictvím automatizace úkonů, šablon, přehledů a spousty dalších vychytávek."
                }
                buttonText={"Firemní výhody"}
                sxButton={{
                  bgcolor: {
                    xs: publicBlueMain,
                    sm: publicBlueMain,
                    md: publicBlueDark,
                  },
                  mt: { xs: "30px", sm: "30px", md: "50px" },
                  mb: { xs: "60px", sm: "50px" },
                }}
                sxProps={{
                  maxWidth: { xs: "550px", sm: "550px", md: "340px" },
                }}
                sxText={{ maxWidth: { xs: "550px", sm: "550px", md: "260px" } }}
                onButtonClick={() => {
                  navigate("/profirmu");
                }}
              />
            </Grid>
            <Grid item xs={24} sm={24} md={8} sx={{}}>
              <ArticleText
                whiteText={true}
                blueText={"Fakturace pro účetní"}
                title={"Jste účetní?"}
                text={
                  "S Profakturou je každá spolupráce s vaším klientem jednodušší. Exportujte doklady do účetních programů, předcházejte chybám a ulehčete si práci."
                }
                buttonText={"Více informací"}
                sxButton={{
                  bgcolor: {
                    xs: publicBlueMain,
                    sm: publicBlueMain,
                    md: publicBlueDark,
                  },
                  mt: { xs: "30px", sm: "30px", md: "50px" },
                  mb: { xs: "60px", sm: "50px" },
                }}
                sxProps={{
                  maxWidth: { xs: "550px", sm: "550px", md: "340px" },
                }}
                sxText={{ maxWidth: { xs: "550px", sm: "550px", md: "260px" } }}
                onButtonClick={() => {
                  navigate("/ucetni");
                }}
              />
            </Grid>
          </Grid>
        </BgLayout>
      </Container>
    </Box>
  );
}
