import { useContext } from "react";
import { ArticlesContext } from "../../../App";

export const withContext = (Component) => {
  const Wrapper = (props) => {
    const data = useContext(ArticlesContext)?.data;

    return <Component data={data} {...props} />;
  };

  return Wrapper;
};
