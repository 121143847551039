import { AppBar, Box, Toolbar, Typography } from "@mui/material";
import { ReactComponent as LogoSvg } from "../../../Resources/Design/Graphics/svg/LogoSlon.svg";
import React from "react";
import {
  appDarkBlue,
  navbarBlue,
  zindex,
  appBgColor,
} from "../../Design/SourcesJS/themes";
import { Link, useNavigate } from "react-router-dom";
import { withRouter } from "../../Routes/withRouter";

const height = "45px";

const Logo = () => {
  const editedIcon = React.cloneElement(<LogoSvg />, {
    style: {
      height: "100%",
      maxWidth: "33px",
      fill: navbarBlue,
    },
  });
  return <>{editedIcon}</>;
};

function LogoNavbar({ disallowNavigate = false }) {
  let navigate = useNavigate();

  return (
    <header>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="static"
          sx={{
            maxHeight: height,
            height: height,
            boxShadow: 0,
            bgcolor: appBgColor,
            ".MuiToolbar-root": {
              minHeight: height,
              maxHeight: height,
              height: height,
              px: "16px",
              maxWidth: 1200,
              width: "100%",
              mx: "auto",
              zIndex: zindex.navbar,
              backgroundColor: "white",
              boxShadow: 0,
              bgcolor: appBgColor,
            },
          }}
        >
          <Toolbar>
            <Box
              component={Link}
              sx={{
                position: "relative",
                marginRight: "auto",
                display: "flex",
                justifyContent: "center",
                textDecoration: "none",
                p: "5px",
                pl: "0px",
                "&:hover": {
                  cursor: "pointer",
                },
              }}
              onClick={() => {
                if (!disallowNavigate) {
                  navigate("/");
                }
              }}
              to="/"
            >
              <Logo />

              <Typography
                variant="logo"
                sx={{
                  ml: "8px",
                  height: "100%",
                  color: appDarkBlue,
                }}
              >
                profaktura
              </Typography>
            </Box>
          </Toolbar>
        </AppBar>
      </Box>
    </header>
  );
}

export default withRouter(LogoNavbar);
