import { Box, Skeleton, Typography, useScrollTrigger } from "@mui/material";
import React from "react";
import {
  publicBlueDark,
  publicBlueMain,
} from "../../../../Design/SourcesJS/publicTheme";
import { NAVBAR_HEIGHT, NAVBAR_SPACE } from "../../../Classes/Constants";

export default function BlogSideNavigationSkeleton({
  sectionsList = null,
  currentSection,
}) {
  const threshold = 900;
  const diff = 80;

  const elevated = useScrollTrigger({
    disableHysteresis: true,
    threshold,
    target: window ? window : undefined,
  });

  return (
    <div>
      <Box
        sx={{
          position: elevated ? "absolute" : "fixed",
          top: elevated
            ? `${threshold + diff + 10}px`
            : `${NAVBAR_HEIGHT * 2 + diff}px`,
          //left: NAVBAR_SPACE,
          ml: NAVBAR_SPACE,
          textAlign: "left",
          maxWidth: "190px",
          width: "auto",
          minWidth: "160px",
        }}
      >
        <Skeleton
          variant="text"
          sx={[
            {
              width: "75%",
              maxWidth: "110px",
              fontSize: "16px",
              pb: "15px",
            },
          ]}
        />
        {Object.keys([{}, {}, {}, {}, {}]).map((keyName, index) => (
          <Skeleton
            key={"SideNavbarBlogISkeleton" + index}
            variant="text"
            sx={[
              {
                fontSize: "12px",
                mt: "5px",
                width: "auto",
                maxWidth: "140px",
                p: "5px",
                pl: "0px",
              },
            ]}
          />
        ))}
      </Box>
    </div>
  );
}
