import React from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Divider, Typography, useMediaQuery } from "@mui/material";
import { appDarkBlue, appRed, zindex } from "../../Design/SourcesJS/themes";
import BaseButton from "../Buttons/BaseButton";
import MobileMenuIcon from "../Buttons/AnimationIcon/MobileMenuIcon";
import { useLocation, useNavigate } from "react-router-dom";
import { publicNavbar } from "../../Public/Classes/Navigation";
import { theme } from "../../../App";
import KeyIcon from "@mui/icons-material/Key";
/*import {
  mainNavigation,
  navigationMenu,
  navigationMobile,
} from "../../Routes/Core/navigation";*/

function DrawerIcon({ icon }) {
  let editedIcon = React.cloneElement(icon, {
    style: {
      fill: "black",
      width: "30px",
      height: "30px",
    },
  });

  return editedIcon;
}

export default function MobileDrawerMenu({
  whiteVersion = false,
  state = false,
  setState,
  navItems = [...publicNavbar],
}) {
  const navigate = useNavigate();
  const location = useLocation();

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState(open);
  };

  const list = () => (
    <Box
      sx={{ width: "auto", bgcolor: "white" }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {navItems.map((data, index) => (
          <Box
            key={data.name ? data.name : "Empty"}
            onClick={() => {
              console.log(index);
              navigate(data.path);
            }}
          >
            <ListItem
              sx={{
                mt: index == 0 ? "10px" : "0px",
                "&:hover": {
                  bgcolor: "rgba(200, 200, 200, .1)",
                },
              }}
              disablePadding
            >
              <ListItemButton sx={{ mt: index == 0 ? "46px" : "0px" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    mt: "10px",
                  }}
                >
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        sx={{
                          mb: "10px",
                          color: appDarkBlue,
                          fontFamily: "Gordita",
                          fontWeight: "bold",
                          fontSize: "14px",
                          lineHeight: "14px",
                        }}
                      >
                        {data.name}
                      </Typography>
                    }
                  />
                </Box>
              </ListItemButton>
            </ListItem>
            {index !== navItems.length - 1 && (
              <ListItem key={"Divider" + index} disablePadding>
                <Box
                  sx={{ width: "100%", height: "1px", ml: "18px", my: "0px" }}
                >
                  <Divider />
                </Box>
              </ListItem>
            )}
          </Box>
        ))}
        <ListItem key={"SignoutListItem"} disablePadding>
          <Box
            key={"MenuItemProfileButton"}
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              height: "80px",
              mt: "44px",
              mb: "36px",
              flexDirection: "column",
              alignItems: "center",
              alignContent: "center",
            }}
          >
            <BaseButton
              text={"Vyzkoušet"}
              onClick={() => {
                if (window)
                  window.open("https://app.profaktura.cz/registrace", "_self");
              }}
              sxProps={{
                bgcolor: appRed,
                "&:hover": {
                  bgcolor: appRed,
                },
                my: "0px",
                alignItems: "center",
                maxWidth: "100px",
                height: "55px",
                minHeight: "55px",
              }}
              sxText={{
                alignSelf: "center",
                textAlign: "center",
                fontSize: "15px",
                lineHeight: "15px",
              }}
            />

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                cursor: "pointer",
                alignItems: "center",
                alignContent: "center",
                p: "8px",
                mt: "8px",
              }}
              onClick={() => {
                if (!window) return;
                window.open("https://app.profaktura.cz/login", "_self");
              }}
            >
              <KeyIcon sx={{ color: appDarkBlue }} />
              <Typography
                sx={{
                  color: appDarkBlue,
                  fontWeight: "bold",
                  fontFamily: "Gordita",
                  fontSize: "13px",
                  lineHeight: "13px",
                  mt: "1px",
                  ml: "5px",
                }}
              >
                Přihlásit se
              </Typography>
            </Box>
          </Box>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <div>
      <React.Fragment key={"TopDrawerMenu"}>
        {/*<IconButton
          icon={state ? <MenuOpenIcon /> : <MenuIcon />}
          iconProps={{ fill: `white` }}
          sxProps={{
            width: "42px",
            height: "42px",
          }}
          onClick={() => {
            setState((prev) => !prev);
          }}
        />*/}
        <MobileMenuIcon
          open={state}
          onClick={() => {
            setState((prev) => !prev);
          }}
          whiteVersion={whiteVersion}
        />
        <SwipeableDrawer
          anchor={"top"}
          open={state}
          disableScrollLock={true}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
          BackdropProps={{ invisible: true }}
          sx={{
            display: { xs: "flex", sm: "flex", md: "none" },
            zIndex: zindex.menuOverlay,
          }}
          disableSwipeToOpen
        >
          {list()}
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}
